import React from 'react';
import Page from 'components/Page';
import {
  TopFooter,
} from 'components/Layout';
import {
  Button,
  Col,
  Row,
  Input,
  FormText
} from 'reactstrap';
import StorageService from '../../services/StorageService';
import axios from 'axios';
import productImage from 'assets/img/products/placeholder.webp';
import NoResult from 'components/NoResult';
import ImageCarousel from 'components/ImageCarousel';
import { func } from 'services/function';
import YouTube from 'react-youtube';
import queryString from 'query-string';
import { product } from 'services/product.js';
import moment from 'moment-timezone';

class FacebookProductPage extends React.Component {
  _isMounted = true;
  constructor(props) {
    super(props);
    this.state = {
      product: null,
      productList: [],
      quantity: 1,
      redirect: false,
      tags: [],
      limitReached: false,
      discountMsg: '',
      image: '',
      configItems: [],
      carouselImages: [],
      subPrice: 0,
      email: '',
      items: [],
      conditionMsg: ''
    };

    this.changeMainImage = this.changeMainImage.bind(this);
  }

  up = async (index, cur_qty) => {
    const { product, items } = this.state;

    let item = {
        weight: product.weight[index].weight,
        price: product.weight[index].fixed_price ? product.weight[index].fixed_price: JSON.parse(product.price_data)[0].price,
        quantity: (cur_qty) ? parseInt(cur_qty) + 1 : 1,
        fixed: product.weight[index].fixed_price ? true : false
    };

    let quantity = 1;
    for(let i of items) {
      if(i) quantity += i.quantity;
    }

    let valid = await this.checkCart(quantity);

    if(valid) {
      items[index] = item;
      this.setState({ items }); 
    } 
  }

  down = (index, cur_qty) => {
    const { product, items } = this.state;
    let qty = parseInt(cur_qty);
    if(cur_qty < 1) {
        qty = 0;
    } else {
        qty = cur_qty - 1;
    }

    let item = {
      weight: product.weight[index].weight,
      price: product.weight[index].fixed_price ? product.weight[index].fixed_price: JSON.parse(product.price_data)[0].price,
      quantity: qty,
      fixed: product.weight[index].fixed_price ? true : false
    };

    items[index] = item;

    this.setState({
        items
    });  
  }

  changeWeightQuantity = async (index, event) => {
    const { product, items } = this.state;

    if(event.target.value && isNaN(event.target.value)) {
        return;
    }
    
    let quantity = parseInt(event.target.value);
    let arr = [...items];

    let item = {
      weight: product.weight[index].weight,
      price: product.weight[index].fixed_price ? product.weight[index].fixed_price: JSON.parse(product.price_data)[0].price,
      quantity: quantity < 0 ? "0" : event.target.value ? quantity : "",
      fixed: product.weight[index].fixed_price ? true : false
    };

    arr[index] = item;

    let total_quantity = 0;
    for(let i of arr) {
      if(i) total_quantity += i.quantity;
    }

    let valid = await this.checkCart(total_quantity);
    if(valid) {
      this.setState({ items: arr }); 
    }    
  };


  onDown = () => {
    let quantity = this.state.quantity;
    quantity -= 1;
    if(quantity >= 1) {
      this.setState({ quantity });
    }
  }

  onUp = async () => {
    let quantity = this.state.quantity;

    if(quantity) {
      quantity = parseInt(quantity) +1;
    } else {
      quantity = 1;
    }

    let valid = await this.checkCart(quantity);
    if(valid) {
      this.setState({ quantity });
    }   
  };

  checkPriceMod = quantity => {
    const { product } = this.state;
    let price = JSON.parse(product.price_data)[0].price;
    if(product.price_mod.length) {
      for(let pm of product.price_mod) {
        if(quantity >= pm.min_qty && quantity <= pm.max_qty) {
          price = JSON.parse(pm.price_data)[0].price;
        }
      }
    }

    let final_price = price;
    let sub_price = 0;

    if(product.product_type === 'set' && product.set && product.set.set_type === 'TOP UP') {
      if(this.state.configItems.length > 0) {
        for(let i of this.state.configItems) {
          if(i && i.price) {
            sub_price += i.price;
          } 
        }
      }
      final_price = parseFloat(final_price) + sub_price;
    }

    if(product.product_type === 'combo') {
      if(this.state.configItems.length > 0) {
        for(let i of this.state.configItems) {
          if(i && i.addOn === 'TOP UP' && i.price) {
            sub_price += i.price;
          } 
        }
      }
      final_price = parseFloat(final_price) + sub_price;
    }

    return final_price;
  };

  weightAddToCart = () => {
    const store_code = this.props.store ? this.props.store.store_code : '';
    let items = this.state.items;
    const product = this.state.product;
    let total_added_quantity = 0;
    // let arr = [];

    for(let i of items) {
      if(i && i.quantity > 0) {
        // arr.push(i);
        total_added_quantity += i.quantity;
      }
    }

    if(total_added_quantity < 1) {
      alert('Item with zero quantity is not permitted');
      return;
    } 

    let limitReached = func.checkCart(product, total_added_quantity, true);
    if(limitReached) {
        alert('Quantity exceed order limit.');
        return;
    } 

    let maxQuantityReached = func.checkExceedMaxQty(product, total_added_quantity, true);
    if(maxQuantityReached) {
        alert(maxQuantityReached);
        return;
    }

    let cartItem = StorageService.getSession('cart');
    let newArr = cartItem && cartItem.length ? cartItem : [];
    let available_dates = [];

    let d_start = '';
    let d_end = '';

    if(product.product_condition.length) {
      for(let i of product.product_condition) {
        if(i.condition_type === "Available") {
          available_dates.push({
            start_date: i.start_date,
            end_date: i.end_date
          });

          d_start = i.start_date;
          d_end = i.end_date;
        }
      }
    }

    for(let i of items) {
      if(i.quantity > 0) {
        let cartitem_id = func.randStr(20);
        let foundIndex = newArr.findIndex(el => el.id === product.id && el.store_id == product.store_id && el.weight === i.weight);
        let price = i.price;
        if(!i.fixed) {
          price = func.checkPriceMod(product, total_added_quantity);
        }

        if(foundIndex > -1) {
          newArr[foundIndex].quantity += i.quantity;
          newArr[foundIndex].price += price;
        } else {
          newArr.push({
            cartitem_id: cartitem_id,
            store_id: product.store_id,
            store_code: product.store_code,
            id: parseInt(product.id),
            product_no: product.product_no,
            unit: JSON.parse(product.price_data)[0].unit,
            custom_product: product.custom_product ? product.custom_product : null,
            product_type: product.product_type,
            name: product.name,
            image: product.image,
            quantity: i.quantity,
            price: price,
            available_dates: available_dates,
            d_start: d_start,
            d_end: d_end,
            type: 'main',
            addOn: "",
            weight: i.weight,
            fixed_price: i.fixed
          });
        }
      }
    }

    this.saveCart(newArr);
  };

  addToCart = () => {
    const store_code = this.props.store ? this.props.store.store_code : '';
    if(!this.state.quantity || this.state.quantity < 1) {
      alert('Item with zero quantity is not permitted');
      return;
    }

    let subItems = [];

    if(this.state.configItems.length> 0) {
      for(let i of this.state.configItems) {
        if(i) {
          subItems.push(i);
        }
      }
    }

    let cartItem = StorageService.getSession('cart');
    let product = this.state.product;
    let added_quantity = parseInt(this.state.quantity);
    let configItems = subItems;
    let allProduct = this.state.productList;
    let limitReached = func.checkCart(product, added_quantity, true);
    if(limitReached) {
        alert('Quantity exceed order limit.');
        return;
    } 

    let maxQuantityReached = func.checkExceedMaxQty(product, added_quantity, true);
    if(maxQuantityReached) {
        alert(maxQuantityReached);
        return;
    }

    if(product.product_type === 'set') {
      let isValid = func.checkIfValidItem(product.set.product_list, configItems, product.set.set_type);

      if(!isValid) {
          alert('Invalid selected item.');
          return;
      }
    }

    if(product.product_type === 'combo') {
      let isValid = func.checkComboIfValidItem(product.combo, configItems);

      if(!isValid) {
          alert('Invalid selected item.');
          return;
      }
    }

    let newArr = cartItem && cartItem.length ? cartItem : [];
    let found = newArr.some(el => el.id === product.id && el.store_id == product.store_id);
    let quantity = parseInt(added_quantity);
    let cartitem_id = func.randStr(20);
    let available_dates = [];

    let d_start = '';
    let d_end = '';

    if(product.product_condition.length) {
      for(let i of product.product_condition) {
        if(i.condition_type === "Available") {
          available_dates.push({
            start_date: i.start_date,
            end_date: i.end_date
          });

          d_start = i.start_date;
          d_end = i.end_date;
        }
      }
    }

    if (found) {
      let item = newArr.find(el => el.id === product.id && el.store_id == product.store_id);
      if(product.product_type === 'set' || product.product_type === 'combo') {
          newArr.push({
              cartitem_id: cartitem_id,
              store_id: product.store_id,
              store_code: product.store_code,
              id: product.id,
              product_no: product.product_no,
              unit: JSON.parse(product.price_data)[0].unit,
              custom_product: product.custom_product ? product.custom_product : null,
              product_type: product.product_type,
              name: product.name,
              image: product.image,
              quantity: quantity,
              price: func.checkPriceMod(product, quantity),
              available_dates: available_dates,
              d_start: d_start,
              d_end: d_end,
              type: 'main',
              addOn: "",
              weight: 1,
              fixed_price: false
            });
      } else {
          let currentQty = parseInt(item.quantity);
          currentQty += quantity;
          item.quantity = currentQty;
          item.price = func.checkPriceMod(product, currentQty);
      }
    } else {
        newArr.push({
            cartitem_id: cartitem_id,
            store_id: product.store_id,
            store_code: product.store_code,
            id: product.id,
            product_no: product.product_no,
            unit: JSON.parse(product.price_data)[0].unit,
            custom_product: product.custom_product ? product.custom_product : null,
            product_type: product.product_type,
            name: product.name,
            image: product.image,
            quantity: quantity,
            price: func.checkPriceMod(product, quantity),
            available_dates: available_dates,
            d_start: d_start,
            d_end: d_end,
            type: 'main',
            addOn: "",
            weight: 1,
            fixed_price: false
        });
    }

    if(product.product_type === 'set' || product.product_type === 'combo') {
      if(configItems.length) {
        let sub_price = 0;
        for(let i of configItems) {
            let p = allProduct.find(el => el.id == i.product_id);
            if(p) {
              let price = '0.00';
              if(product.product_type === 'set' && product.set.set_type==='TOP UP') {
                // price = func.checkPriceMod(p, i.qty);
                price = i.price;
                sub_price += parseFloat(price);
              }

              if(product.product_type === 'combo' && i.addOn==='TOP UP') {
                // price = func.checkPriceMod(p, i.qty);
                price = i.price;
                sub_price += parseFloat(price);
              }

              

              newArr.push({
                cartitem_id: cartitem_id,
                store_id: product.store_id,
                store_code: product.store_code,
                id: i.product_id,
                product_no: i.product_no,
                unit: i.unit,
                custom_product: i.custom_product,
                product_type: i.product_type,
                name: i.name,
                image: i.image,
                quantity: i.qty,
                price: price,
                available_dates: '',
                d_start: '',
                d_end: '',
                type:'sub',
                addOn: i.addOn,
                weight: 1,
                fixed_price: false
              });
            } 
        }
      }
    }
    this.saveCart(newArr);
  };

  saveCart(items) {
    const url = process.env.REACT_APP_UNITY_API2_URL;
    let session_key = StorageService.getSession('session_key');
    const company = this.props.company ? this.props.company.CompanyID : '';
    const company_code = this.props.company ? this.props.company.CompanyCode : '';
    const store = this.props.store ? this.props.store.store_code : '';
    if (!store || !company || !company_code) {
      return;
    }

    if (!this.state.email) {
      alert('Please enter your email');
      return;
    }

    const data = {
      session_key: session_key,
      company_id: company,
      company_code: company_code,
      store_code: store,
      email: this.state.email,
      items: items
    };

    axios
      .post(url + ':3030/v1/cart/order/addtocart', data)
      .then(response => {
        const result = response.data;

        if (result.error == true && result.message != 'Success') {
          return;
        }

        this.setState({
          quantity: 1,
          limitReached: false,
          configItems: [],
          subPrice: 0,
          email: ''
        })

        this.props.history.push({
          pathname: `/${company_code}/${store}/added-to-cart`,
        });

      })
      .catch(error => {
        alert('Service Error. Please try again.');
      });
  };

  changeHandler = async (event) => { 
    let val = event.target.value;
    let valid = true;
    if(event.target.value) {
      val = parseInt(event.target.value);
    }

    let name = event.target.name;


    if(val >= 1) {
      valid = await this.checkCart(val);
    }

    if(valid) {
      this.setState({ [name]: val, }); 
    }
  };

  changeConfigHandler = (i, event) => {
    let configItems = [...this.state.configItems];
    if(event.target.value) {
      let product_list = JSON.parse(this.state.product.set.product_list);
      let p = product_list.find(e=> e.product_id === event.target.value);
      let price = 0;
      let unit = "";
      if(p) {
        price = parseFloat(p.price[0].price); 
        unit = parseFloat(p.price[0].unit); 
      } 
      configItems[i] = {
        product_id: event.target.value,
        qty: 1,
        price,
        addOn: this.state.product.set.set_type,
        id: "",
        product_no: p.product_no,
        unit: unit,
        custom_product: p.custom_product ? p.custom_product : null,
        product_type: p.product_type,
        name: p.name,
        image: p.image,
      };
    } else {
      if(configItems.length > 0) {
        configItems[i] = undefined;
      }
    }
    this.setState({
      configItems
    }, () => this.checkPriceMod(this.state.quantity));
    
  };

  changeConfigComboHandler = (i, j, k, event) => {
    //i=b,j=a,k=k
    let configItems = [...this.state.configItems];
    if(event.target.value) {
      let product_list = JSON.parse(this.state.product.combo[j].product_list);
      let p = product_list.find(e=> e.product_id === event.target.value);
      let price = 0;
      let unit = "";
      if(p) {
        price = parseFloat(p.price[0].price); 
        unit = parseFloat(p.price[0].unit); 
      } 

      configItems[i+k] = {
        product_id: event.target.value,
        qty: 1,
        price,
        addOn:this.state.product.combo[j].set_type,
        id: this.state.product.combo[j].id,
        product_no: p.product_no,
        unit: unit,
        custom_product: p.custom_product ? p.custom_product : null,
        product_type: p.product_type,
        name: p.name,
        image: p.image,
      };
    } else {
      if(configItems.length > 0) {
        configItems[i+k] = undefined;
      }
    }

    this.setState({
      configItems
    }, () => this.checkPriceMod(this.state.quantity));
  };

  getStoreProduct = () => {
    const url = process.env.REACT_APP_UNITY_API2_URL;
    let session_key = StorageService.getSession('session_key');
    const consumer = StorageService.getSession('consumer');
    const company = this.props.company ? this.props.company.CompanyID : '';
    const store = this.props.store ? this.props.store.store_code : '';
    if (!store) {
      return;
    }

    const data = {
      session_key: session_key,
      company_id: company,
      store_code: store,
      user_session_key:consumer.user_session_key,
    };
    

    axios
      .post(url + ':3030/v1/cart/silo/products', data)
      .then(response => {
        const result = response.data;

        if (result.error == true && result.message != 'Success') {
          return;
        }

        if (result.data.length > 0) {

          let product = result.data.find(
            el => el.id == this.props.match.params.product,
          );

          let message = '';
          let carouselImages = [];
          let image = productImage;

          if (product) {
            func.splitTags(product.tags);
            let price = func.financial(JSON.parse(product.price_data)[0].price);
            let count = 0;
            if(product.price_mod.length) {
              for(let pm of product.price_mod) {
                price = func.financial(JSON.parse(pm.price_data)[0].price);
                if(product.product_type === 'weight') {
                  message += `$${JSON.parse(product.price_data)[0].price} per gram`;
                  message += '<br/>';
                  message += `Purchase ${pm.min_qty} to ${pm.max_qty} to get $${price} per gram <br/>Valid from ${pm.start_dt} to ${pm.end_dt}`;
                } else {
                  message += `Purchase ${pm.min_qty} to ${pm.max_qty} pcs to get $${price} for each <br/>Valid from ${pm.start_dt} to ${pm.end_dt}`;
                }

                if(count + 1 < product.price_mod.length) {
                  message += '<br/><br/>';
                }
                count++;
              }
            }

            if(product.image) {
              image = product.image;
            }

            if(product.product_image.length) {
                carouselImages = product.product_image;
                carouselImages.unshift({
                  id: 0,
                  image: image
                });
            }
          }

          this.setState({
            productList: result.data,
            product: product,
            discountMsg: message,
            image,
            carouselImages
          }, () => this.checkCart(this.state.quantity));
          
        }
      })
      .catch(error => {
        //alert(error);
      });
  };

  checkCart(added_qty) {
    // let cart = StorageService.getSession('cart');
    // let available_stock = JSON.parse(this.state.product.qty_data)[0].qty; 
    // let ignore_stock = this.state.product.ignore_stock;
    // let cart_item = null;
    // if(cart) {
    //   cart_item = cart.find(el=>el.id == this.props.match.params.product);
    // }
    // let cart_item_qty = cart_item ? cart_item.quantity : 0;

    // if(ignore_stock < 1) {
    //   if((parseInt(cart_item_qty) + parseInt(added_qty)) > available_stock) {
    //     this.setState({
    //       limitReached: true
    //     });
    //   } else {
    //     this.setState({
    //       limitReached: false
    //     });
    //   }
    // } else {
    //   this.setState({
    //     limitReached: false
    //   });
    // }

    let cart = StorageService.getSession('cart');
    let cart_item = [];
    let cart_item_qty = 0;
    let quantity = added_qty;
    let product = this.state.product;
    let available_stock = JSON.parse(this.state.product.qty_data)[0].qty;
    let valid = true;

    if(cart) {
      cart_item = cart.filter(el=>el.id == product.id && el.store_id == product.store_id);
    }

    for(let c of cart_item) {
      cart_item_qty += c.quantity;
    }   

    let total_quantity = cart_item_qty + quantity;

    if(product.ignore_stock < 1) {  
      if(total_quantity > available_stock) {
        let str = '';
        if(cart_item_qty > 0) {
          str += 'You have '+ cart_item_qty + ' in your cart. ';
        }
        alert(str +'Quantity exceed order limit.');
        valid = false;
      }
    } 

    if(product.product_condition.length > 0) {
      let now_date = moment().format('YYYY-MM-DD');
      for(let i of product.product_condition) {
        if(i.condition_type === 'MaxQuantity') {
          let start_date = moment(i.start_date).format('YYYY-MM-DD');
          let end_date = moment(i.end_date).format('YYYY-MM-DD');
          if(now_date >= start_date && now_date<= end_date) {
            if(total_quantity > parseInt(i.params)) {
              let str = '';
              if(cart_item_qty > 0) {
                str += 'You have '+ cart_item_qty + ' in your cart. ';
              }
              alert(str+'You are allowed to purchase maximum ' + i.params + ' per order for this item');
              valid = false;
            }
          }
        }
      }
    }

    return valid;
  }

  changeMainImage(image) {
    this.setState({
      image
    });
  }

  getProductInfo = (product_id, store_id) => {
    try {
      product.edit(product_id, store_id)
        .then(data => {
          if(this._isMounted) {
            if(data) {
              let product = data;
              let message = '';
              let cond_message = '';
              let valid_message = '';
              let carouselImages = [];
              let image = productImage;

              if (product) {
                  let price = func.financial(JSON.parse(product.price_data)[0].price);
                  let count = 0;
                  if(product.price_mod.length) {
                    for(let pm of product.price_mod) {
                        price = func.financial(JSON.parse(pm.price_data)[0].price);
                        if(product.product_type === 'weight') {
                        message += `$${JSON.parse(product.price_data)[0].price} per gram`;
                        message += '<br/>';
                        message += `Purchase ${pm.min_qty} to ${pm.max_qty} to get $${price} per gram <br/>Valid from ${pm.start_dt} to ${pm.end_dt}`;
                        } else {
                        message += `Purchase ${pm.min_qty} to ${pm.max_qty} pcs to get $${price} for each <br/>Valid from ${pm.start_dt} to ${pm.end_dt}`;
                        }
                        
                        if(count + 1 < product.price_mod.length) {
                        message += '<br/><br/>';
                        }
                        count++;
                    }
                  }

                  if(product.product_condition.length) {
                    let pcount = 0;
                    for(let pm of product.product_condition) {
                        if(pm.condition_type === 'Available') {
                          cond_message += `Available Delivery/Pickup Date: ${pm.start_date} to ${pm.end_date}`;
                        }

                        if(pm.condition_type === 'MaxQuantity') {
                          cond_message += `Max. purchase quantity per order: ${pm.params}<br/>`;
                          cond_message += `Valid from ${pm.start_date} to ${pm.end_date}`;
                        }
                        
                        if(pcount + 1 < product.product_condition.length) {
                          cond_message += '<br/><br/>';
                        }
                        pcount++;
                    }
                  }

                  if(product.image) {
                  image = product.image;
                  }

                  if(product.product_image.length) {
                      carouselImages = product.product_image;
                      carouselImages.unshift({
                      id: 0,
                      image: image
                      });
                  }
              }

              this.setState({
                  product: product,
                  discountMsg: message,
                  conditionMsg: cond_message,
                  validMsg: valid_message,
                  image,
                  carouselImages,
                  tags: func.splitTags(product.tags)
              });
          }
          }
            
        });
    } catch (err) {}
  }; 

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    if(params && params.store) {
      this.getProductInfo(this.props.match.params.product, params.store);
    } 
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { product, items, conditionMsg } = this.state;

    const { company, store } = this.props;
    return (
      <>
        <Page className="container ps-page-content" title="">
          {product ? (
            <>
              <div className="ps-item-detail">
                <Row>
                  <Col md={5}>
                    <div className="img-wrapper">
                      <img
                        src={this.state.image ? this.state.image : productImage}
                        className="ps-img"
                      />
                    </div>
                    <ImageCarousel images={this.state.carouselImages} changeMainImage={this.changeMainImage}/>
                  </Col>
                  <Col md={7}>
                    <h1 className="ps-name">
                      {product ? product.name : ''}<br/>
                      {product ? product.chn_name : ''}
                    </h1>
                    <p className="category">
                      {product ? product.category : ''}
                    </p>
                    {this.state.tags.length > 0 && (
                      <div className="ps-tags">
                        {this.state.tags.map((i, index) => {
                          return <span key={index}>{i}</span>;
                        })}
                      </div>
                    )}
                    <p>
                      {product
                        ? JSON.parse(product.qty_data)[0].qty < 1
                          ? product.ignore_stock
                            ? 'In stock'
                            : 'Out of stock'
                          : 'In stock'
                        : ''}
                    </p>

                    {product && product.eng_description && 
                      <p className="ps-subtitle"
                        dangerouslySetInnerHTML={{
                          __html: `${product.eng_description} ${product.chn_description && `<br /><br />${product.chn_description}`}`,
                        }}
                      ></p>
                    }

                    {product && product.product_type === 'combo' && product.combo && 
                      product.combo.map((i, a) => {
                        return (
                          <div className="pd-opt-list" key={a}>
                            <span>Select {i.name}: {i.required > 0 && `(At least ${i.required}/set)`}</span>
                            <ul>
                              {JSON.parse(i.product_list).map((j, b) => {
                                return (
                                    <li key={b}>
                                      {j.name} {j.required > 0 && `(At least ${j.required}/set)`} { i.set_type === 'TOP UP' && ` - $${func.financial(j.price[0].price)}`}
                                    </li>
                                );
                              })}
                            </ul>
                          </div>
                        );
                      })
                    }

                    {product && product.set && JSON.parse(product.set.product_list).length > 0 &&
                      <div className="pd-opt-list">
                        <span>Select options: </span>
                        <ul>
                          {JSON.parse(product.set.product_list).map((item, index) => {
                            return (
                                <li key={index}>
                                  {item.name} {item.required > 0 && `(At least ${item.required}/set)`} { product.set.set_type === 'TOP UP' && ` - $${func.financial(item.price[0].price)}`}
                                </li>
                            );
                          })}
                        </ul>
                      </div>
                    }

                    {product && product.product_type === 'combo' && <p className="ps-subtitle-topup">Price may vary depending on add-on items selected</p>}
                    {product && product.set && product.set.set_type === 'TOP UP' && <p className="ps-subtitle-topup">Price may vary depending on add-on items selected</p>}

                    {product && product.product_type === 'combo' && product.combo && 
                      product.combo.map((i, a) => {
                        return (
                          <div className="pd-opt-list" key={a}>
                            <span>Select {i.name}: </span>
                            {[...Array(i.item_count)].map((j, b) => {
                              let k=func.getIncrementValue(a, product.combo);
                              return (
                                <div key={b} className="d-flex align-items-center">
                                  <Input
                                  type="select"
                                  name="configProductId"
                                  value={this.state.configItems[b+k] ? this.state.configItems[b+k].product_id : ''}
                                  onChange={this.changeConfigComboHandler.bind(this, b, a, k)}
                                  required
                                  className="product-config-select"
                                  disabled={(JSON.parse(i.product_list)).length < 1 ? true:false}
                                  data-id={b+k}

                                >
                                  <option value="">
                                    Please select item
                                  </option>
                                  {JSON.parse(i.product_list).map((l, c) => {
                                      return (
                                          <option key={c} value={l.product_id}>
                                            {l.name} {l.required > 0 && `(At least ${l.required}/set)`}
                                          </option>
                                      );
                                    })}
                                </Input> 
                              </div>
                              );
                            })} 
                          </div>
                        );
                      })
                    }
                    
                    {product && product.product_type === 'set' && [...Array(product.set.item_count)].map((i, idx) => (
                      <div key={idx} className="d-flex align-items-center">
                      <Input
                      
                      type="select"
                      name="configProductId"
                      value={this.state.configItems[idx] ? this.state.configItems[idx].product_id : ''}
                      onChange={this.changeConfigHandler.bind(this, idx)}
                      required
                      className="product-config-select"
                      disabled={(JSON.parse(product.set.product_list)).length < 1 ? true:false}
                    >
                      <option value="">
                        Please select item
                      </option>
                      {JSON.parse(product.set.product_list).map((item, index) => {
                          return (
                              <option key={index} value={item.product_id}>
                                {item.name} {item.required > 0 && `(At least ${item.required}/set)`}
                              </option>
                          );
                        })}
                    </Input> 
                    </div>
                    ))} 

                    {product && product.product_type === 'combo' && product.combo && (product.combo).map((item, index) => 
                          JSON.parse(item.product_list).length < 1 && <p key={index} className="ps-subtitle-topup">*{item.name} are out of stock</p>
                    )}

                    {product && product.product_type === 'set' && product.set && (JSON.parse(product.set.product_list)).length < 1 && <p className="ps-subtitle-topup">*Option items are out of stock</p>}
                    <br/>
                    {conditionMsg && <div><span className="ps-subtitle" dangerouslySetInnerHTML={{__html: conditionMsg}}></span><br/><br/></div>}
                    <span className="ps-subtitle" dangerouslySetInnerHTML={{__html: this.state.discountMsg}}></span>
                    {product.nett_price > 0 && <span className="ps-subtitle"><br/><br/>Promo discount can't be applied for this item.<br/><br/></span>}
                  
                      
                    {product && product.product_type === 'weight' &&
                        <div className="mb-3">
                            <ul className="weight-option title">
                                <li>Weight</li>
                                <li>Price</li>
                                <li className="text-center">Quantity</li>
                            </ul>
                            {product.weight.map((item, index) => {
                                return (
                                <ul className="weight-option" key={index}>
                                    <li>{`${item.weight}g`}</li>
                                    <li>${item.fixed_price ? func.financial(item.weight * item.fixed_price) : func.financial(item.weight * JSON.parse(product.price_data)[0].price) }</li>
                                    <li className="quantity-input">
                                        <Button color="link" className="btn-sub" onClick={()  => this.down(index, items[index] ? items[index].quantity : 0)}>-</Button>
                                        <Input type="text" min="0" value={items[index] ? items[index].quantity : 0} onChange={this.changeWeightQuantity.bind(this,index)} />
                                        <Button color="link" className="btn-add" onClick={()  => this.up(index, items[index] ? items[index].quantity : 0)}>+</Button>
                                    </li>
                                </ul>
                                );
                            })}
                            <br/>
                            <Input className="email-input" type="email" name="email" required placeholder="Enter your email" value={this.state.email} onChange={e => this.setState({email: e.target.value})}/>
                            <FormText>We will send you a cart email with instructions to complete your purchase.</FormText><br/><br/>
                            <div className="ps-control">
                              <Button
                              color="link"
                              className={"ps-cart-button" + (product
                                ? JSON.parse(product.qty_data)[0].qty < 1
                                  ? product.ignore_stock
                                    ? ''
                                    : ' disabled-btn'
                                  : ''
                                : '')}
                              disabled={
                                product
                                  ? JSON.parse(product.qty_data)[0].qty < 1
                                    ? product.ignore_stock
                                      ? false
                                      : true
                                    : false
                                  : false
                              }
                              onClick={()=>this.weightAddToCart()}
                            >
                              {product
                              ? JSON.parse(product.qty_data)[0].qty < 1
                                ? product.ignore_stock
                                  ? 'Add to Cart'
                                  : 'Out of Stock'
                                : 'Add to cart'
                              : ''}
                            </Button>
                          </div>
                        </div>
                    }

                    {product && product.product_type !== 'weight' &&
                      <div>

                    <p className="ps-price">
                      {product && (this.checkPriceMod(this.state.quantity) != JSON.parse(product.price_data)[0].price) &&
                      <span className="original-price">${func.financial(JSON.parse(product.price_data)[0].price)}</span>}
                      ${func.financial(this.checkPriceMod(this.state.quantity))}
                    </p>

                    <Input className="email-input" type="email" name="email" required placeholder="Enter your email" value={this.state.email} onChange={e => this.setState({email: e.target.value})}/>
                    <FormText>We will send you a cart email with instructions to complete your purchase.</FormText><br/>
                    <div className="ps-control">
                      <div className="ps-input">
                        <Button color="link" className="btn-sub" onClick={this.onDown}>-</Button>
                        <Input
                          type="number"
                          name="quantity"
                          min="1"
                          max={product ? product.ignore_stock ? "9999" : JSON.parse(product.qty_data)[0].qty : "9999"}
                          value={this.state.quantity}
                          onChange={this.changeHandler}
                        />
                        <Button color="link" className="btn-add" onClick={this.onUp}>+</Button>
                      </div>

                      <Button
                        color="link"
                        className={"ps-cart-button" + (product
                          ? JSON.parse(product.qty_data)[0].qty < 1
                            ? product.ignore_stock
                              ? ''
                              : ' disabled-btn'
                            : ''
                          : '')}
                        disabled={
                          product
                            ? JSON.parse(product.qty_data)[0].qty < 1
                              ? product.ignore_stock
                                ? false
                                : true
                              : false
                            : false
                        }
                        onClick={() => this.addToCart()}
                      >
                        {product
                        ? JSON.parse(product.qty_data)[0].qty < 1
                          ? product.ignore_stock
                            ? 'Add to Cart'
                            : 'Out of Stock'
                          : 'Add to cart'
                        : ''}
                      </Button>
                      
                    </div>
                    </div>
                  }
                  </Col>
                </Row>
              </div>

              {product && product.video_url && (
                <div className="ps-item-desc video-desc">
                  <h2 className="ps-section-title">Product Video</h2>
                  <div className="video-wrapper">
                    <YouTube
                      videoId={product.video_url}
                      opts={{
                        playerVars: {
                          rel: 0,
                        },
                      }}
                      onReady={this._onReady}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <NoResult />
          )}
        </Page>
        <TopFooter store={this.props.store} company={this.props.company} />
      </>
    );
  }
}
export default FacebookProductPage;
