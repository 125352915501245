import withBadge from 'hocs/withBadge';
import React from 'react';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from 'utils/bemnames';
import {
  IoIosPerson,
  IoIosCart,
  IoIosMenu,
  IoIosLogOut,
  IoIosHappy,
  IoIosBasket,
  IoIosGift
} from 'react-icons/io';
import { NavLink as Link } from 'react-router-dom';
import ss from '../../../services/StorageService';
import moment from 'moment-timezone';
import {func} from 'services/function';

const bem = bn.create('header');
const cart = ss.getSession('cart');

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    store: null,
    company: null,
    cartItem: 0,
    isClose: false
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleOverlayMenu = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.ps-overlay-menu').classList.toggle('open');
  };

  goToProfile = () => {
    const { children } = this.props;
    this.props.history.push(
      `/${this.props.match.params.company}/${this.props.match.params.store}/your-profile`,
    );
  };

  render() {
    let items = [];
    const user = ss.getSession('consumer');
    const logo = this.props.company ? this.props.company.CompanyLogo : '';
    const company = this.props.company ? this.props.company.CompanyCode : '';
    const store = this.props.store ? this.props.store.store_code : '';
    const name = this.props.company ? this.props.company.CompanyName : '';
    let isClose = false;

    if(this.props.store) {
      isClose = func.isClose(this.props.store);
    }

    if (cart && cart.length > 0) {
      items = cart.filter(i => i.type === 'main');
    }

    let home_url = `/${company}/${store}`;

  if(this.props.company) {
    if(this.props.company.Has_Store === 1) {
      home_url = `/${company}`;
    }
  }

    return (
      
      <Navbar light expand className={bem.b('bg-white')}>
        <div className="container">
          <Link to={home_url} className="mr-2 ps-logo-wrapper">
            <img src={logo} className="ps-header-logo" />
            <span>{name}</span>
          </Link>

          <Nav navbar className={`ps-header-icon-item ${bem.e('nav-right')}`}>
            {this.props.store && this.props.store.enable_silo &&
            <> 
            {/* {!isClose && <NavItem>
                <Link
                  className="nav-link"
                  to={`/${company}/${store}/cart`}
                  exact={false}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="ps-cart" id="d-cart-number">
                    <IoIosCart size={25} className="can-click text-secondary" />
                  </div>
                </Link>
              </NavItem>} */}
              
              {user === null ? (
              <NavItem>
                <Link
                  className="nav-link"
                  to={`/${company}/${store}/login`}
                  exact={false}
                  style={{ cursor: 'pointer' }}
                >
                  <IoIosPerson size={25} className="can-click text-secondary" />
                  <span
                    style={{
                      marginLeft: '0.35rem',
                      color: '#3d5170',
                      fontSize: '0.85rem',
                    }}
                  >
                    Login
                  </span>
                </Link>
              </NavItem>
            ) : (
              <NavItem>
                <NavLink id="Popover2">
                  <IoIosPerson
                    size={25}
                    onClick={this.toggleUserCardPopover}
                    className="can-click text-secondary"
                  />
                  <span
                    style={{
                      marginLeft: '0.35rem',
                      color: '#3d5170',
                      fontSize: '0.85rem',
                      cursor: 'pointer',
                    }}
                  >
                    {user.name}
                  </span>
                </NavLink>
                <Popover
                  placement="bottom-end"
                  isOpen={this.state.isOpenUserCardPopover}
                  toggle={this.toggleUserCardPopover}
                  target="Popover2"
                  className="p-0 border-0"
                  style={{ minWidth: 250 }}
                >
                  <PopoverBody className="p-0 border-light ps-dropdown-link">
                    <ListGroup flush>
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light"
                      >
                        <Link
                          to={`/${company}/${store}/your-profile`}
                          exact={false}
                          onClick={this.toggleUserCardPopover}
                        >
                          <IoIosHappy /> Profile
                        </Link>
                      </ListGroupItem>
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light"
                      >
                        <Link
                          to={`/${company}/${store}/voucher`}
                          exact={false}
                          onClick={this.toggleUserCardPopover}
                        >
                          <IoIosGift /> Your Voucher
                        </Link>
                      </ListGroupItem>
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light"
                      >
                        <Link
                          to={`/${company}/${store}/track-order`}
                          exact={false}
                          onClick={this.toggleUserCardPopover}
                        >
                          <IoIosBasket /> Track My Order
                        </Link>
                      </ListGroupItem>
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light"
                        onClick={this.props.handleLogout}
                      >
                        <IoIosLogOut /> Logout
                      </ListGroupItem>
                    </ListGroup>
                  </PopoverBody>
                </Popover>
              </NavItem>
            )}
              </>
            }
            
          </Nav>
          <Nav
            navbar
            className={`ml-auto ps-hamburger-menu ${bem.e('nav-right')}`}
          >
            {this.props.store && this.props.store.enable_silo && (

              <Button color="link" onClick={this.handleOverlayMenu}>
              <IoIosMenu size={25} />
            </Button>
            )}
            
          </Nav>
        </div>
      </Navbar>
    );
  }
}

export default Header;
