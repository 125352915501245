import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label, FormText } from 'reactstrap';
import axios from 'axios';
import StorageService from '../services/StorageService';
import { NavLink as Link } from 'react-router-dom';
import Recaptcha from 'react-google-invisible-recaptcha';

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      contact: '',
      email: '',
      company: '',
      companyName: '',
      dnd: true,
      success: false,
      password:'',
      confirmPassword: '',
      isError: false,
      errors: {
        contact: '',
      },
    };
  }

  changeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });

    if(event.target.name === 'confirmPassword') {
      this.checkPassword(event.target.value);
    }
  };

  changeContactHandler = event => {
    let phone = (event.target.value).replace(/\s/g, "");
    let valid = true;
    let name = event.target.name;
    if(phone && isNaN(phone)) {
        valid = false;
    }
    
    if(valid) {
        this.setState({
            [name]: (event.target.value).replace(/\s/g, "")
        });  
    }
  };

  handleChangeCheckbox = event => {
    this.setState({
      dnd: event.target.checked
    });
  };

  checkPassword(confirmPwd) {
    let password = this.state.password;
    this.setState({
      isError: (password !== confirmPwd) ? true: false
    })
  }

  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }

  handleSubmit = event => {
    event.preventDefault();

    const { contact } = this.state;

    let errors = this.state.errors;
    errors.contact = (!contact) ? 'Invalid input. Please enter contact no.' : (contact.length < 7) ? 'Invalid input. Please enter contact that must be at least 7 characters.' : '';

    this.setState({ errors });

    if(this.validateForm(errors)) {
      if(this.state.isError) return;
      this.recaptcha.execute();
    }
  };

  onResolved = async () => {
    const token = this.recaptcha.getResponse();
    this.recaptcha.reset();
    this.validateHuman(token);
  };

  validateHuman = token => {
    const url = process.env.REACT_APP_API_URL;
    const formData = {
      token: token,
    };

    axios
      .post(url + ':3000/v1/ecart/verifyCaptcha', formData)
      .then(response => {
        const result = response.data;
        if (result.error == true && result.message != 'Success') {
          return false;
        }
        if (result.data) {
          if (result.data.success) {
            this.register();
          }
        }
      })
      .catch(error => {
        alert(error);
        return false;
      });
  };

  register = () => {
    const { name, company, contact, email, dnd, password } = this.state;
    const url = process.env.REACT_APP_UNITY_API2_URL;
    let key = StorageService.getSession('session_key');
    const formData = {
      session_key: key,
      name: name,
      contact: contact,
      email: email,
      company_id: company,
      dnd: dnd ? 0 : 1,
      password: password
    };

    axios
      .post(url + ':3030/v3/cart/customer/register', formData)
      .then(response => {
        const result = response.data;

        if (result.error == true && result.message != 'Success') {
          alert(result.message);
          return;
        }

        if (result.data) {
          result.data.company = this.props.match.params.company;
          StorageService.setSession('consumer', result.data);
          alert("Your registration is successful. Go to homepage...");

          this.props.history.push(
            `/${this.props.match.params.company}/${this.props.match.params.store}`,
          );
        }
      })
      .catch(error => {
        alert(error.response.data.message);
      });
  };

  componentDidMount() {
    if (this.props.company) {
      this.setState({
        company: this.props.company.CompanyID,
        companyName: this.props.company.CompanyName,
      });
    }
  }

  render() {
    const { errors } = this.state;
    const { usernameLabel, usernameInputProps } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} className="ps-form form-state">
        <FormGroup>
              <Label>Company</Label>
              <Input
                type="text"
                name="companyName"
                value={this.state.companyName}
                onChange={this.changeHandler}
                disabled={true}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for={usernameLabel}>{usernameLabel}</Label>
              <Input
                {...usernameInputProps}
                name="email"
                value={this.state.email}
                onChange={this.changeHandler}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Name</Label>
              <Input
                type="text"
                name="name"
                value={this.state.name}
                onChange={this.changeHandler}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Contact</Label>
              <Input
                type="text"
                name="contact"
                value={this.state.contact}
                onChange={this.changeContactHandler}
                required
              />
              {errors.contact.length > 0 && <FormText color="danger">{errors.contact}</FormText>}
            </FormGroup>
            <FormGroup>
              <Label>Password</Label>
              <Input
                type="password"
                name="password"
                values={this.state.password}
                onChange={this.changeHandler}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Confirm Password</Label>
              <Input
                type="password"
                name="confirmPassword"
                values={this.state.confirmPassword}
                onChange={this.changeHandler}
                required
              />
              {this.state.isError && <FormText color="danger">Your password and confirmation password do not match.
              </FormText>}
              
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input 
                type="checkbox" 
                name="dnd"              
                checked={this.state.dnd}
                onChange={this.handleChangeCheckbox} />{' '}
                Subscribe to Updates
              </Label>
            </FormGroup> <br/>

            <div className="d-flex align-items-center">
              <Button
                size="lg"
                color="primary"
                className="border-0 mr-3"
                type="submit"
              >
                Register
              </Button>
            </div>
        <Recaptcha
          ref={ref => (this.recaptcha = ref)}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE}
          onResolved={this.onResolved}
          badge="bottomleft"
        />
      </Form>
    );
  }
}
export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

RegisterForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

RegisterForm.defaultProps = {
  authState: 'SIGNUP',
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

export default RegisterForm;
