import {
  Content,
  WebFooter,
  TopHeader,
  OverlayMenu,
  LinkBar
} from 'components/Layout';
import React from 'react';
import ScrollArrow from 'components/ScrollArrow';
import ss from '../../../services/StorageService';
import Helmet from 'react-helmet';
import whatsapp from 'assets/img/whatsapp.png';
import Loader from 'components/Loader';
import { Offline } from "react-detect-offline";

class WebLayout extends React.Component {
  logout = () => {
    ss.clearSession('cart');
    ss.clearSession('consumer');
    ss.clearSession('access');
    ss.clearSession('fp');
    window.location.reload();
  };

  handleChange = (event, newValue) => {
    this.setState({value: newValue});
  };

  render() {
    const { children } = this.props;
    return (
      <main className="cr-app">
        <Helmet><title>{this.props.store ? this.props.store.name : ''}</title></Helmet>
        <Content fluid>         
          <TopHeader 
            handleLogout={this.logout} 
            totalItems={this.props.totalItems}
            company={this.props.company}
            store={this.props.store}
            {...this.props} />
          {children}
          
          <LinkBar company={this.props.company}
            store={this.props.store}/>
          <WebFooter />
          {this.props.store && this.props.store.show_watsapp > 0 && <a href={` https://wa.me/${this.props.store.watsapp}`} target="_blank"><img src={whatsapp} className="whatsapp-floating-icon"/></a>}
          <OverlayMenu {...this.props} handleLogout={this.logout} />
          <Loader/>
          <ScrollArrow />
          <Offline polling={{
            url: 'https://unityapi2.pseudoscops.com:3030/v1/cart/ping',
            interval: 18000
            }}>
            <div id="noconnection">
              <div>
                <strong>No Internet Connection</strong><br/><br/>
                <span>Please check your internet connection<br/> and try again.</span><br/><br/>
                <button type="button" onClick={()=>window.location.reload()}>Retry</button>
              </div>
            </div>
          </Offline>
        </Content>

        
      </main>
    );
  }
}

export default WebLayout;
