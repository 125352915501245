import axios from 'axios';
import StorageService from '../services/StorageService';
const url = "https://unityapi2.pseudoscops.com";
const unity_url = "https://zosuite.pseudoscops.com";

export const product = {
    
    edit(product_id, store_id) {
        let session_key = StorageService.getSession('session_key');
        let company = StorageService.getSession('company');
        let store = StorageService.getSession('store');

        const formData = {
            session_key: session_key,
            company_id: company.CompanyID,
            store_code: store.store_code,
            store_id: store_id,
            product_id: product_id
        };
      
        return axios
        .post(url + ":3030/v1/cart/products/detail", formData)
        .then(response => {
            const result = response.data;
            if (result.data) {
                return result.data;
            }

            return null; //boolean
        })
        .catch(error => {
            return null;
        });
    },

    get_recommend(category) {
        let session_key = StorageService.getSession('session_key');
        let company = StorageService.getSession('company');
        let store = StorageService.getSession('store');

        const formData = {
            session_key: session_key,
            company_id: company.CompanyID,
            store_code: store.store_code,
            category: category
        };
      
        return axios
        .post(url + ":3030/v2/cart/products/recommend", formData)
        .then(response => {
            const result = response.data;
            if (result.data) {
                return result.data;
            }

            return []; //boolean
        })
        .catch(error => {
            return [];
        });
    },

    get_cart_product_info(products) {
        let session_key = StorageService.getSession('session_key');
        let company = StorageService.getSession('company');
        let store = StorageService.getSession('store');

        const formData = {
            session_key: session_key,
            company_id: company.CompanyID,
            store_code: store.store_code,
            products: products
        };
      
        return axios
        .post(url + ":3030/v1/cart/products/update", formData)
        .then(response => {
            const result = response.data;
            if (result.data) {
                return result.data;
            }

            return []; 
        })
        .catch(error => {
            return [];
        });
    },
};