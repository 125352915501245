import React from 'react';
import { MdSearch } from 'react-icons/md';
import { Form, Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';
import axios from 'axios';
import StorageService from '../services/StorageService';


class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: [],
      productName: ''
    };
  }

  changeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  search = event => {
    event.preventDefault();
    if(this.props.isProductPage) {
      this.props.filter(this.state.productName);
    } else {

      const company = StorageService.getSession('company');
      const store = StorageService.getSession('store');
      const companyCode = company ? company.CompanyCode : '';
      const storeCode = store ? store.store_code : '';

      window.location.href=`/${companyCode}/${storeCode}?search=${this.state.productName}`;
    }

    this.setState({
      productName: ''
    })
  };

  render() {
    return (
      <Form
      inline
      className={`cr-search-form`}
      onSubmit={this.search}
    >
      <InputGroup style={{ position: 'relative' }}>
        <Input placeholder="Search product" name="productName"
                value={this.state.productName}
                onChange={this.changeHandler}/>
        <InputGroupAddon addonType="append">
          <Button color="primary" type="submit">
            <MdSearch />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </Form>
    );
  }
}

export default SearchInput;
