import product2 from 'assets/img/products/07-380x434.jpg';

export const productData = [
  {
    name: 'Cras pose psum default 1',
    image: product2,
  },
  {
    name: 'Cras pose psum default 2',
    image: product2,
  },
  {
    name: 'Cras pose psum default 3',
    image: product2,
  },
  {
    name: 'Cras pose psum default 4',
    image: product2,
  },
  {
    name: 'Cras pose psum default 5',
    image: product2,
  },
  {
    name: 'Cras pose psum default 6',
    image: product2,
  },
  {
    name: 'Cras pose psum default 7',
    image: product2,
  },
  {
    name: 'Cras pose psum default 8',
    image: product2,
  },
  {
    name: 'Cras pose psum default 9',
    image: product2,
  },
  {
    name: 'Cras pose psum default 10',
    image: product2,
  },
  {
    name: 'Cras pose psum default 11',
    image: product2,
  },
  {
    name: 'Cras pose psum default 12',
    image: product2,
  },
  {
    name: 'Cras pose psum default 9',
    image: product2,
  },
  {
    name: 'Cras pose psum default 10',
    image: product2,
  },
  {
    name: 'Cras pose psum default 11',
    image: product2,
  },
  {
    name: 'Cras pose psum default 12',
    image: product2,
  },
  {
    name: 'Cras pose psum default 9',
    image: product2,
  },
  {
    name: 'Cras pose psum default 10',
    image: product2,
  },
  {
    name: 'Cras pose psum default 11',
    image: product2,
  },
  {
    name: 'Cras pose psum default 12',
    image: product2,
  },
  {
    name: 'Cras pose psum default 9',
    image: product2,
  },
  {
    name: 'Cras pose psum default 10',
    image: product2,
  },
  {
    name: 'Cras pose psum default 11',
    image: product2,
  },
  {
    name: 'Cras pose psum default 12',
    image: product2,
  },
  {
    name: 'Cras pose psum default 9',
    image: product2,
  },
  {
    name: 'Cras pose psum default 10',
    image: product2,
  },
  {
    name: 'Cras pose psum default 11',
    image: product2,
  },
  {
    name: 'Cras pose psum default 12',
    image: product2,
  },
];

export const productDetail = {
  id: '123',
  name: 'Cras pose psum default 12',
  image: product2,
  subtitle:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  desc:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  price: '$599.00',
};
