import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import ss from '../../../services/StorageService';
  
class LinkBar extends React.Component {
    render() {
        const { store, company } = this.props;
        
        return (
            store && store.ecartpage && 
            Object.keys(store.ecartpage).some((k) => store.ecartpage[k] > 0) &&
            <div className="ps-link-bar">
                <div className="container">
                    <ul>
                        {store.ecartpage.enable_privacypolicy > 0 && <li><Link to={`/${company.CompanyCode}/${store.store_code}/privacy-policy`}>Privacy Policy</Link></li>}
                        {store.ecartpage.enable_terms > 0 && <li><Link to={`/${company.CompanyCode}/${store.store_code}/terms-and-conditions`}>Terms & Conditions</Link></li>}
                        {store.ecartpage.enable_faq > 0 && <li><Link to={`/${company.CompanyCode}/${store.store_code}/faq`}>FAQ</Link></li>}
                    </ul>
                </div>
            </div>
        );
    }
}

export default LinkBar;
  