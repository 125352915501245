import React from 'react';
import { MdSearch } from 'react-icons/md';
import {
  Form,
  Input,
  Collapse,
  Row,
  Col,
  FormGroup,
  Label,
  Button,
} from 'reactstrap';
import { IoIosRefresh, IoMdColorFilter } from 'react-icons/io';
import StorageService from '../services/StorageService';
import axios from 'axios';

class FilterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: 'All',
      sort: 'low',
      categoryList: [],
    };
  }

  changeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  resetFilter = () => {
    this.setState({
      category: 'All',
    });
  };

  handleSubmit = event => {
    event.preventDefault();
  };

  getProductCategory = id => {
    const url = process.env.REACT_APP_UNITY_API2_URL;
    const data = {
      session_key: 'MEmnAwcpRdVhAjWqqswddeRxTBrMsPzdmNvwXRyr',
      company_id: id,
    };

    axios
      .post(url + ':3002/v1/product/category', data)
      .then(response => {
        const result = response.data;

        if (result.error == true && result.message != 'Success') {
          alert(result.message);
          return;
        }

        this.setState({
          categoryList: result.data,
        });
      })
      .catch(error => {
        alert(error);
      });
  };

  getCompanyDetail = () => {
    const url = process.env.REACT_APP_API_URL;
    const data = {
      CompanyCode: this.props.data.match.params.company,
    };

    axios
      .post(url + ':3000/v1/ecart/getCompany', data)
      .then(response => {
        const result = response.data;

        if (result.error == true && result.message != 'Success') {
          alert(result.message);
          return;
        }
        if (result.data) {
          this.getProductCategory(result.data.CompanyID);
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  componentDidMount() {
    this.getCompanyDetail();
  }

  render() {
    const { categoryList } = this.state;
    return (
      <div className="ps-filter-form">
        <Form onSubmit={this.handleSubmit}>
          <Row form>
            <Col md={this.props.type === 'horizontal' ? 3 : 12}>
              <FormGroup>
                <Label>Category</Label>
                <Input
                  type="select"
                  name="category"
                  value={this.state.category}
                  onChange={this.changeHandler}
                >
                  <option value="All">All</option>
                  {categoryList.length > 0 &&
                    categoryList.map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                </Input>
              </FormGroup>
            </Col>
            {/* <Col md={this.props.type === 'horizontal' ? 3 : 12}>
              <FormGroup>
                <Label>Sort by</Label>
                <Input
                  type="select"
                  name="sort"
                  value={this.state.sort}
                  onChange={this.changeHandler}
                >
                  <option value="low">Low to High</option>
                  <option value="high">High to Low</option>
                </Input>
              </FormGroup>
            </Col> */}
          </Row>
          <div className="d-flex justify-content-end">
            <Button
              color="primary"
              className="d-block mr-3"
              onClick={() => this.props.resetFilter(this.state.category)}
            >
              <IoMdColorFilter />
              Filter
            </Button>
            <Button
              color="link"
              className="theme-button-outline d-block"
              onClick={() => {
                this.props.resetFilter('All');
                this.resetFilter();
              }}
            >
              <IoIosRefresh />
              Reset
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default FilterForm;
