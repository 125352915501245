import Page from 'components/Page';
import Login, { STATE_LOGIN } from 'components/LoginForm';
import Register, { STATE_SIGNUP } from 'components/RegisterForm';
import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import { BottomHeader, WebFooter } from 'components/Layout';
import { Redirect } from 'react-router-dom';
import StorageService from '../services/StorageService';
import ls from '../services/LocalStorageService';
import axios from 'axios';
import AltLoginAlertModal from 'components/AltLoginAlertModal';
import PrivacyPolicyModal from 'components/PrivacyPolicyModal';

const style={
  color: '#495057',
  fontSize: '12px'
};

const style2={
  textDecoration: 'underline',
  color: '#544b82',
  cursor: 'pointer'
};


class AuthPage extends React.Component {
  state={
    loginBy: '',
    open: false,
    privacyModal: false
  };

  toggle = () => () => {
    return this.setState({
      privacyModal: !this.state.privacyModal
    });
  };

  handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      this.props.history.push('/login');
    } else {
      this.props.history.push('/signup');
    }
  };

  handleLogoClick = () => {
    this.props.history.push('/');
  };

  render() {
    // const {company} = this.props;
    // let home_url = `/${this.props.match.params.company}/${this.props.match.params.store}`;
    // if(company) {
    //   if(company.Has_Store === 1) {
    //     home_url = `/${this.props.match.params.company}`;
    //   }
    // }

    // if (StorageService.getSession('consumer')) {
    //   return (
    //     <Redirect
    //       to={home_url}
    //     />
    //   );
    // }

    return (
      <>
        <BottomHeader
          className="ps-bottom-header"
          store={this.props.store}
          company={this.props.company}
          breadcrumbs={[]}
          isProductPage={false}
        />
        <Page className="container ps-page-content auth" title="">
          <Row className="justify-content-md-center">
            <Col md={6} className="ps-auth-login">
              <h2 className="ps-section-title">Login</h2>
              <Login
                authState={this.props.authState}
                onChangeAuthState={this.handleAuthState}
                onLogoClick={this.handleLogoClick}
                company={this.props.company}
                store={this.props.store}
                match={this.props.match}
              />
              <br/>
              <div style={style}>By logging in, you agree to our <span style={style2} onClick={this.toggle()}>Privacy Policy</span></div>
            </Col>
          </Row>
          <PrivacyPolicyModal isOpen={this.state.privacyModal} toggle={this.toggle}/>
        </Page>
      </>
    );
  }
}

export default AuthPage;
