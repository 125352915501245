const cartapi = {
    login_customer: ':3030/v1/cart/customer/login',
    get_customer_voucher: ':3030/v1/cart/customer/voucher',
    delete_customer_address: ':3030/v1/cart/customer/address/delete',
    get_session_key: ':3030/v2/cart/session',
    create_cutomer_address: ':3030/v1/cart/customer/address/create',
    register_customer: ':3030/v2/cart/customer/register',
    get_customer_order: ':3030/v1/cart/customer/order',
    redeem_voucher: ':3030/v1/cart/customer/voucher/redeem',
    get_store: ':3030/v2/cart',
    get_schedule_time: ':3030/v2/cart/schedule',
    update_customer_password: ':3030/v1/cart/customer/unityuser/password/update',
    get_order_detail: ':3030/v1/cart/customer/order/detail',
    get_schedule_location: ':3030/v2/cart/schedule/type',
    get_store_product: ':3030/v1/cart/silo/products',
    update_customer_address: ':3030/v1/cart/customer/address/update',
    get_customer_detail: ':3030/v1/cart/customer',
    create_order: ':3030/v2/cart/order/create',
    update_customer_detail: ':3030/v1/cart/customer/update',
    get_store_detail: ':3030/v2/cart/store',
    get_token: ':3030/v1/cart/customer/unityuser/token',
    add_paypal_detail: ':3030/v1/cart/order/payment/add',
    get_paynow_qr: ':3030/v1/cart/order/payment/paynowqr',
    verify_captcha: ':3000/v1/ecart/verifyCaptcha',
    get_product_category: ':3030/v1/cart/store/category'
}

export default cartapi;
