export const countryData = [
  { name: 'Australia', value: 'Australia', iso: 'au' },
  { name: 'Indonesia', value: 'Indonesia', iso: 'id' },
  { name: 'Malaysia', value: 'Malaysia', iso: 'my' },
  { name: 'Singapore', value: 'Singapore', iso: 'sg' },
  { name: 'Thailand', value: 'Thailand', iso: 'th' }
];

export const regionData = [
  { name: 'North' }, 
  { name: 'South'}, 
  { name: 'East'}, 
  { name: 'West'}, 
  { name: 'Central'} 
];

export const orderData = [
  {
    order_id: 'SO-20-000058',
    order_type: 'Order',
    service_type: 'Service Order',
    total_price: '513.60',
    total_tax: '33.60',
    total_outstanding: '513.60',
    total_discount: '0.00',
    total_paid: '0.00',
    order_status: 'Cancelled',
    payment_status: 'Pending Payment',
    remark: null,
    itemcount: '1',
    order_by: 'szadmin',
    order_dt: '2020-09-05T14:27:46.000Z',
    invoice_no: null,
  },
  {
    order_id: 'SO-20-000058',
    order_type: 'Order',
    service_type: 'Service Order',
    total_price: '513.60',
    total_tax: '33.60',
    total_outstanding: '513.60',
    total_discount: '0.00',
    total_paid: '0.00',
    order_status: 'Cancelled',
    payment_status: 'Pending Payment',
    remark: null,
    itemcount: '1',
    order_by: 'szadmin',
    order_dt: '2020-09-05T14:27:46.000Z',
    invoice_no: null,
  },
];

export const orderDetailsData = {
  order_id: 'DM-20-0928YHGB7GV6',
  order_status: 'Order',
  company_id: '',
  store_code: '',
  service_type: '',
  total_price: '',
  total_rounding: '',
  total_outstanding: '29.80',
  total_discount: '0.00',
  total_tax: '1.83',
  remark: '',
  itemcount: 1,
  items: [
    {
      cartitem_id: '1BURZ55TUFB0FU52X6R6',
      product_id: 11,
      product_no: 'BAK0003',
      name: 'Passion fruit',
      unit: 'unit',
      reference: null,
      data: '',
      custom_data: null,
      service_report: null,
      custom_product: null,
      qty: 2,
      price: '$28.00',
      discount: '$0.00',
      product_type: 'unit',
    },
  ],
  payment_items: [],
  tax_items: [
    {
      tax_id: 'ZHJX1QB6EE9RPDAF9EIF',
      tax_type_id: 7,
      name: 'GST',
      rate: '7',
      amount: '1.83',
    },
  ],
  schedule: {
    schedule_date: '18/08/2020',
    slot_id: 1,
    starttime: '09:30',
    endtime: '17:00',
  },
  address: {
    address: 'Blk27 Bishan St91',
    unit: '01-22',
    country: 'Singapore',
    postal_code: '540240',
    contact: '+65',
    name: 'Dr Jones',
    email: 'tianboon.ak@gmail.com',
    order_msg: 'please leave at door',
  },
  billing: {
    address: 'Blk27 Bishan St91',
    unit: '01-22',
    country: 'Singapore',
    postal_code: '540240',
    contact: '+65',
    name: 'Dr Jones',
    email: 'tianboon.ak@gmail.com',
    billing_msg: '',
    payment_mode: 'Cash',
  },
};
