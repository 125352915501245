import React from 'react';
import { IoIosSearch } from 'react-icons/io';

const NoResult = () => {
  return (
    <div className="no-result">
      <div className="content">
        <IoIosSearch />
        <p>No Result Found</p>
      </div>
    </div>
  );
};

export default NoResult;
