import Page from 'components/Page';
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardDeck,
  CardGroup,
  CardHeader,
  CardTitle,
  Col,
  ListGroup,
  ListGroupItem,
  CardImg,
  CardText,
  Row,
  Collapse,
  Input,
  InputGroup,
  InputGroupAddon,
  Form
} from 'reactstrap';
import { getColor } from 'utils/colors';
import { BottomHeader, FilterBar, StoreBottomHeader } from 'components/Layout';
import { productData } from 'data/product';
import { regionData } from 'data/common';
import Pagination from 'react-bootstrap-4-pagination';
import ReactPaginate from 'react-paginate';
import FilterForm from 'components/FilterForm';
import NoResult from 'components/NoResult';
import StorageService from '../../services/StorageService';
import axios from 'axios';
import { Redirect } from 'react-router';
import storeImage from 'assets/img/store/placeholder.jpg';
import companyFunc from 'data/company';
import { company } from 'faker';
import { MdLocationOn, MdClear, MdSort } from "react-icons/md";
import { IoIosRefresh, IoMdColorFilter } from 'react-icons/io';
import { store } from 'services/api';
import moment from 'moment-timezone'
import {func} from 'services/function';

const today = new Date();
const lastWeek = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - 7,
);

class StorePage extends React.Component {
  paginate = StorageService.getSession('h_paginate');
  _isMounted = true;
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      hoverItem: 0,
      currentPage: (this.paginate && this.paginate.isback) ? this.paginate.curpage : 0,
      offset: 0,
      perPage: 12,
      pageCount: 0,
      dataList: [],
      isOpenFilter: false,
      orgDetail: null,
      storeList: [],
      lat: 0,
      long: 0,
      isFiltered: false,
      searchKey: '',
      activeKey: 'All',
      filterItem: [],
      isShowLess: false,
      filterKeyOffset: 0,
      noOfRecord: 0,
      location: ''
    };
    this.handleHover = this.handleHover.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.toggleFilterForm = this.toggleFilterForm.bind(this);
    this.filter = this.filter.bind(this);
    this.reset = this.reset.bind(this);
  }

  changeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  clearLocation() {
    let data = [...this.state.storeList];
    data = data.sort((a, b) => Number(a.postal_location) - Number(b.postal_location));
    this.setState({
      storeList: data,
      location:''
    });
    this.receivedData(data);
  }

  searchNearBy = event => {
    event.preventDefault();
    var isValid = new RegExp("\\d{6}");
    if(isValid.test(this.state.location)) {
      this.sortStoreNearBy(this.state.location);
    } else {
      alert("Invalid postal code. Please insert 6-digit postal code.");
    }
  }

  sortStoreNearBy(postal_code) {
    store.searchAddress(postal_code)
    .then(data => {
      if (data.data.found === 1) {
        console.log(data);
        let lat = Number(data.data.results[0].LATITUDE);
        let long = Number(data.data.results[0].LONGITUDE);
        let storeList = [...this.state.storeList];

        if(storeList.length) {
          for(let i of storeList) {
            let distance_diff = 0;
            if(i.postal_latlong) {
              let res = i.postal_latlong.split(",");
              i.latitude = +res[0];
              i.longitude = +res[1];
              let d = this.calculateDistance(lat, long, +res[0], +res[1]);
              distance_diff = d;
            }
            i.distance_diff = distance_diff;
          }
        }

        storeList = storeList.sort((a, b) => a.distance_diff - b.distance_diff);

        this.setState({
          storeList,
          lat,
          long,
          activeKey: 'All',
        });
        this.receivedData(storeList);
      }
    });
  };

  toggleFilterForm() {
    this.setState({
      isOpenFilter: !this.state.isOpenFilter,
    });
  }

  view(code, id, isClose) {
    let company = StorageService.getSession('company');
    let companyCode = (company) ? company.CompanyCode : '';
    StorageService.setSession('h_paginate', { curpage: this.state.currentPage, offset: this.state.offset, isback: true });
    window.location.href = `/${companyCode}/${code}`;
  }

  handleHover(index) {
    this.setState(prevState => ({
      isHovered: !prevState.isHovered,
      hoverItem: index,
    }));
  }

  handlePageChange(e) {
    let paginate = StorageService.getSession('h_paginate');
    let selectedPage
    if(paginate && paginate.isback) {
      selectedPage = paginate.curpage;
    }else{
      selectedPage = e.selected
    }

    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        StorageService.setSession('h_paginate', { curpage: selectedPage, offset: offset, isback: false });

        let data = [];
        if(this.state.activeKey !== 'All') {
          for(let s of this.state.storeList) {
            if(s.zone) {
              if((s.zone).toLowerCase() == this.state.activeKey.toLowerCase()) {
                data.push(s);
              }
            } else {
              data.push(s);
            }
          }
        } else {
          data = this.state.storeList;
        }

        if(this.state.searchKey) {
          data = data.filter(el => (el.name).toLowerCase().includes(this.state.searchKey.toLowerCase()) || el.postal_location.includes(this.state.searchKey));
        }

        this.receivedData(data);
      },
    );
    this.scrollTo();
    //window.scrollTo(0, 0);
  }

  scrollTo() {
    if(document.getElementById("here")) {
      window.scrollTo(0, document.getElementById("here").offsetTop);   
    }
  }

  reset() {
    this.receivedData(this.state.storeList);
  }

  getStoreList = (id) => {
    const url = process.env.REACT_APP_UNITY_API2_URL;
    let session_key = StorageService.getSession('session_key');
    let _sselected = StorageService.getSession('_sselected');
    const data = {
      session_key: session_key,
      company_id: id,
      local_time: moment().format('YYYY-MM-DD HH:mm:ss')  
    };

    axios
      .post(url + ':3030/v2/cart', data)
      .then(response => {
        if(this._isMounted) {
          const result = response.data;

          if (result.error == true && result.message != 'Success') {
            alert(result.message);
            return;
          }

          if(result.data.length > 0) {
            let data = result.data.filter(el => el.enable_silo === 1);
            data = data.sort((a, b) => Number(a.postal_location) - Number(b.postal_location));
            this.setState({
              storeList: data,
            });

            if (_sselected && _sselected !== 'All') {
              this.setState({
                activeKey: _sselected,
                location:''
              });
              let store_list = data;
              store_list = store_list.filter(el => (el.zone).toLowerCase() ==_sselected.toLowerCase() || el.zone === null);
              this.receivedData(store_list);
            } else {
              this.receivedData(data);
            }   
          }
        }
        
      })
      .catch(error => {
       // alert(error);
      });
  };

  receivedData(productData) {
    let paginate = StorageService.getSession('h_paginate');

    let offset = this.state.offset;

    if(paginate) {
      offset = paginate.offset;
    }

    const data = productData;
    const slice = data.slice(
      offset,
      offset + this.state.perPage,
    );

    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      dataList: slice,
      noOfRecord: data.length
    });
  }

  toRad(point) 
  { 
      point = point * Math.PI / 180;
      return point; 
  }; 

  calculateDistance(yourLat, yourLong, storeLat, storeLong) {
    let R = 6371; // km
    let dLat = this.toRad(storeLat-yourLat);
    let dLon = this.toRad(storeLong-yourLong);
    let lat1 = this.toRad(yourLat);
    let lat2 = this.toRad(storeLat);

    let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    let d = R * c;
    return d;
  }

  getCurrentCoords() {
    if ("geolocation" in navigator) {
         navigator.geolocation.getCurrentPosition(
          (position) => { 
            this.setState({
              lat: position.coords.latitude, 
              long: position.coords.longitude });
          },
          (error) => {
            console.error("Error Code = " + error.code + " - " + error.message);
          }
        );
      } 
  }

  filter(search_key) {
    this.setState({
      searchKey: search_key,
      isFiltered: true
    }, () => {
      this.handlePageChange({selected: 0});
    }); 
  };

  filterList = (filter_key) => {
    this.setState({
      activeKey: filter_key,
      location:''
    }, () => {
      StorageService.setSession('_sselected', filter_key);
      this.handlePageChange({selected: 0});
    }); 
  };

  resetFilterKey = (isFirst) => {
    if(this.state.isShowLess) {
      const slice = regionData.slice(0,6);

      this.setState({
        filterKeyOffset: 6,
        filterItem: slice,
        isShowLess: false
      });

      return;
    }

    let filterItem = [...this.state.filterItem];
    let filterKeyOffset = this.state.filterKeyOffset;

    if(filterItem.length < regionData.length) {

      const data = regionData;
      const slice = data.slice(
        filterKeyOffset,
        filterKeyOffset + 6,
      );
  
      let newFilterItem = filterItem.concat(slice);
      if(newFilterItem.length >= regionData.length) {
          this.setState({
            isShowLess: !this.state.isShowLess
          });
      }
  
      this.setState({
        filterKeyOffset: filterKeyOffset + 6,
        filterItem: newFilterItem
      });
    }
  };

  reset() {
    window.location.reload();
  };

  getFilterKey() {
    this.resetFilterKey(true);
  }

  componentDidMount() {
    console.log("print")
  }

  componentDidMount() {
    window.scrollTo(0,0);
    let company = StorageService.getSession('company');
    if(company && company.Has_Store === 1) {
      let companyId = company.CompanyID;
      this.getStoreList(companyId);
      this.getFilterKey();
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {

    return (
      <>
        <StoreBottomHeader
          className="ps-bottom-header"
          breadcrumbs={[]}
          filter={this.filter}
        />

        <Page className="container ps-page-content auth" title="" id="here">
          <FilterBar
            start={this.state.offset + 1}
            end={this.state.offset + this.state.dataList.length}
            total={this.state.storeList.length}
            toggleFilterForm={this.toggleFilterForm}
            showFilter={false}
            showStoreResetButton={this.state.isFiltered}
            resetStore={this.reset}
          />
          <Collapse isOpen={this.state.isOpenFilter}>
            
          </Collapse>
          <Form onSubmit={this.searchNearBy} className="ps-locator">
            <InputGroup style={{ position: 'relative' }}>
              <Input placeholder="Postal code nearby me" name="location"
                      value={this.state.location}
                      maxLength="6" min="6"  max="6"
                      onChange={this.changeHandler}/>
              <InputGroupAddon addonType="append">
                <Button color="primary" type="submit">
                  <MdSort />
                </Button>
              </InputGroupAddon>
            </InputGroup>
            {this.state.location && <Button color="link" type="button" className="clear" onClick={() => this.clearLocation()}>
              <IoIosRefresh />
            </Button>}
            </Form>

          {this.state.filterItem.length > 0 &&
            <>
              <ul className="ps-filter-button">
                {this.state.activeKey !== 'All' && 
                <li className="is-all" onClick={() => this.filterList('All')}><MdClear/> Clear {this.state.activeKey} ({this.state.noOfRecord})</li>}
                {
                  this.state.filterItem.map((item, index) => {
                    return (
                      <li key={index} 
                      className={this.state.activeKey === item.name || this.state.activeKey === 'All' ? 'active' : ''} 
                      onClick={() => this.filterList(item.name)}>{item.name}</li>
                  )})
                }
              </ul>
            </>
          }
          
          <Row>
            {this.state.dataList.length < 1 && <NoResult />}
            {this.state.dataList.length > 0 &&
              this.state.dataList.map((item, index) => {
                return (
                   
                  <Col lg={3} md={6} sm={6} xs={6} key={index}>
                    
                    <Card
                      className="ps-card-item store"
                      onMouseEnter={() => this.handleHover(index)}
                      onMouseLeave={() => this.handleHover(index)}
                      onClick={() => this.view(item.store_code, item.store_id, func.isClose(item))}
                    >
                      
                      <div className="image">
                      {/* <div className="mistletoe"></div> */}
                      {func.isClose(item) ? <div className="close-board"></div> : <div
                          className={`ps-hover-text ${
                            this.state.isHovered &&
                            this.state.hoverItem === index
                              ? ''
                              : 'hide'
                          }`}
                        >
                          
                          <span>View</span>
                        </div>}
                        
                        <img src={item.image_url ? item.image_url : storeImage} />
                      </div>

                      <div className="desc d-flex flex-column">
                        <h3>{item.name}</h3>
                        <p className="location"><MdLocationOn/> {item.location}</p>
                        <div className="ellipsis">
                          <div>
                            <p dangerouslySetInnerHTML={{
                                          __html: item.web_header,
                                        }}></p>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                );
              })}
          </Row>
          <div className="ps-pagination">
            <ReactPaginate
              initialPage={this.state.currentPage}
              forcePage={this.state.currentPage}
              previousLabel={'prev'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pageCount}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              onPageChange={this.handlePageChange}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </div>
        </Page>
      </>
    );
  }
}
export default StorePage;
