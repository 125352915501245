import React from 'react';
import { MdSearch } from 'react-icons/md';
import { Form, Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';

class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: [],
      storeName: ''
    };
  }

  changeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  search = event => {
    event.preventDefault();
    this.props.filter(this.state.storeName);
  };

  render() {
    return (
      <Form
      inline
      className={`cr-search-form`}
      onSubmit={this.search}
    >
      <InputGroup style={{ position: 'relative' }}>
        <Input placeholder="Search store" name="storeName"
                value={this.state.storeName}
                onChange={this.changeHandler}/>
        <InputGroupAddon addonType="append">
          <Button color="primary" type="submit">
            <MdSearch />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </Form>
    );
  }
}

export default SearchInput;
