import React from 'react';
import { Spinner  } from 'reactstrap';

const Loader = () => {
  return (
    <div className="ps-loader">
        <div className="ps-spinner-grow">
            <Spinner type="grow" color="primary"/>
        </div>
    </div>
  );
};

export default Loader;
