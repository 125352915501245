import React from 'react';
import PropTypes from 'utils/propTypes';
import bn from 'utils/bemnames';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import SearchInput from 'components/SearchInput';
import { NavLink as Link } from 'react-router-dom';
import StorageService from '../../../services/StorageService';

const bem = bn.create('page');

const BottomHeader = ({
  title,
  filter,
  isProductPage,
  breadcrumbs,
  tag: Tag,
  className,
  children,
  ...restProps
}) => {
  //const classes = bem.b('px-3', className);
  const company = StorageService.getSession('company');
  const store = StorageService.getSession('store');
  const companyCode = company ? company.CompanyCode : '';
  const storeCode = store ? store.store_code : '';
  let home_url = `/${companyCode}/${storeCode}`;

  let childItem = [];
  if(company) {
    if(company.Has_Store === 1) {
      home_url = `/${companyCode}`;
      if(store) {
          childItem.push({
            name: 'Product',
            to: `/${companyCode}/${storeCode}`,
            active: true
          })
        }
    }
  }

  return (
    <Tag className="ps-bottom-header d-flex align-items-center" {...restProps}>
      <div className="container d-md-flex justify-content-between align-items-center">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={home_url}>Home</Link>
          </BreadcrumbItem>
            {StorageService.getSession('consumer') && childItem.length > 0 &&
            childItem.map(({ name, active, to }, index) => (
              <BreadcrumbItem key={index} active={active}>
                {active ? <Link to={to}>{name}</Link> : name}
              </BreadcrumbItem>
            ))}
        </Breadcrumb>

        {children}
        {/* <SearchInput className="ps-search" filter={filter} isProductPage={isProductPage} /> */}
      </div>
    </Tag>
  );
};

BottomHeader.propTypes = {
  tag: PropTypes.component,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  children: PropTypes.node,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      active: PropTypes.bool,
    }),
  ),
};

BottomHeader.defaultProps = {
  tag: 'div',
  title: '',
};

export default BottomHeader;
