import {
    Content,
    WebFooter,
    FacebookTopHeader
  } from 'components/Layout';
  import React from 'react';
  import ScrollArrow from 'components/ScrollArrow';
  
  class FacebookLayout extends React.Component {
  
    render() {
      const { children } = this.props;
      return (
        <main className="cr-app">
          <Content fluid>
            <FacebookTopHeader company={this.props.company}/>
            {children}
            <WebFooter />
          </Content>
          <ScrollArrow />
        </main>
      );
    }
  }
  
  export default FacebookLayout;
  