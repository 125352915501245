import PropTypes, { func } from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import axios from 'axios';
import StorageService from '../services/StorageService';
import Recaptcha from 'react-google-invisible-recaptcha';


class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      company: '',
      companyName: '',
      password: '',
      success: false,
      isVerified: false,
      token: null,
    };
  }

  changeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  goto = () => {
    this.props.history.push({
      pathname: '/forgot-password',
    });
  };

  redirect() {
    window.location.href = `/${this.props.match.params.company}/${this.props.match.params.store}`;
  }

  handleSubmit = event => {
    event.preventDefault();
    this.recaptcha.execute();
  };

  onResolved = async () => {
    const token = this.recaptcha.getResponse();
    this.recaptcha.reset();
    this.validateHuman(token);
  };

  login = () => {
    const { password, company, id } = this.state;
    const url = process.env.REACT_APP_UNITY_API2_URL;
    let key = StorageService.getSession('session_key');
    const formData = {
      session_key: key,
      user_password: password,
      user_id: id,
      company_id: company,
    };
    axios
      .post(url + ':3030/v1/cart/customer/login/unityuser', formData)
      .then(response => {
        const result = response.data;
        if (result.error == true && result.message != 'Success') {
          alert(result.message);
          return;
        }
        if (result.data) {
          result.data.company = this.props.match.params.company;
          StorageService.setSession('consumer', result.data);
          this.redirect();
        } else {
          alert('Login Failed. Please try again later.');
        }
      })
      .catch(error => {
        alert('Login Failed. You have entered an invalid username or password. Please try again.');
      });
  };

  validateHuman = token => {
    const url = process.env.REACT_APP_API_URL;
    const formData = {
      token: token,
    };

    axios
      .post(url + ':3000/v1/ecart/verifyCaptcha', formData)
      .then(response => {
        const result = response.data;
        if (result.error == true && result.message != 'Success') {
          return false;
        }
        if (result.data) {
          if (result.data.success) {
            this.login();
          }
        }
      })
      .catch(error => {
        alert(error);
        return false;
      });
  };

  componentDidMount() {
    if (this.props.company) {
      this.setState({
        company: this.props.company.CompanyID,
        companyName: this.props.company.CompanyName,
      });
    }
  }

  render() {
    const {
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} className="ps-form">
        <FormGroup>
          <Label for={usernameLabel}>{usernameLabel}</Label>
          <Input
            {...usernameInputProps}
            name="id"
            value={this.state.id}
            onChange={this.changeHandler}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for={passwordLabel}>{passwordLabel}</Label>
          <Input
            {...passwordInputProps}
            name="password"
            value={this.state.password}
            onChange={this.changeHandler}
            required
          />
        </FormGroup>
        <div className="d-flex align-items-center">
          <Button
            size="lg"
            color="primary"
            className="border-0 mr-3"
            type="submit"
          >
            Login
          </Button>
          <Link
            to={`/${this.props.match.params.company}/${this.props.match.params.store}/forgot-password`}
            exact={false}
          >
            Forgot Password?
          </Link>
        </div>


        <Recaptcha
          ref={ref => (this.recaptcha = ref)}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE}
          onResolved={this.onResolved}
          badge="bottomleft"
        />
      </Form>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

LoginForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

LoginForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'mPOS User ID',
  usernameInputProps: {
    type: 'text',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

export default LoginForm;
