import React from 'react';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal
} from 'reactstrap';

const modalStyle={
    fontSize: '0.85rem',
    minWidth: '350px'
};

const listStyle={
    paddingLeft: '15px'
};

class PrivacyPolicyModal extends React.Component {
  render() {
    return (
      <Modal style={modalStyle} isOpen={this.props.isOpen} toggle={this.props.toggle()} centered>
        <ModalHeader toggle={this.props.toggle()}>Privacy Policy</ModalHeader>
        <ModalBody>
            <strong>What We Collect</strong><br/>
            <ol style={listStyle}>
                <li>Email - for account creation and management of user orders such as receipt and order notification</li>
                <li>Contact - to contact user for order fulfilment</li>
                <li>Address - for delivery purpose only</li>
            </ol>
            <strong>Payment Information</strong><br/>
            Please note that we do not collect any payment information. Any payment is to the vendor or payment gateway directly.
            <br/><br/>
            <strong>Data Ownership</strong><br/>
            According to our Privacy Policy , our end users own all the data they provide for the management and the rights to consent to disseminating personal data to 3rd party users to obtain services and products. <br/>
            End-user can request data removal from the UnitySuite database thru our support email. Upon request, all data will be removed from our database within five working days.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.props.toggle()}>I understand</Button>
        </ModalFooter>
        </Modal>
    );
  }
}

export default PrivacyPolicyModal;
