import React, { useState } from 'react';
import PropTypes from 'utils/propTypes';
import {
  ListGroup,
  ListGroupItem,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import ItemsCarousel from 'react-items-carousel';
import productImage from 'assets/img/products/placeholder.webp';

const product_image= [
  {
    id: 1,
    image: "https://cdn.shopify.com/s/files/1/0707/2783/products/IMG_3975_copy_c40a7cb3-dbda-426b-b169-46c20c0883ee_grande.jpg?v=1445830453"
  },
  {
    id: 2,
    image: "https://www.line-stickers.com/wp-content/uploads/2019/12/Hello-Browns-Jumping-Daily-Life-.png"
  },
  {
    id: 3,
    image: "https://images.livemint.com/img/2020/09/30/600x338/2020-08-19T162100Z_619508010_RC24HI945EU4_RTRMADP_3_APPLE-STOCKS_1601424353856_1601424366363.JPG"
  }, 
  {
    id: 4,
    image: "https://pyxis.nymag.com/v1/imgs/020/c73/400de647d6dc1cd2cd941f1f3d62083018-11-gavin.rsquare.w700.jpg"
  }
];

const ImageCarousel = ({ images, changeMainImage }) => {
  const [active, setActive] = useState(0);

  const handleChange = image => {
    // Here, we invoke the callback with the new value
    changeMainImage(image);
  }
  
  return (
    <div className="ps-detail-carousel">
    <ItemsCarousel
        enablePlaceholder
        numberOfPlaceholderItems={4}
        minimumPlaceholderTime={1000}
        placeholderItem={<div className="placeholder"></div>}
        numberOfCards={4}
        gutter={0}
        showSlither={true}
        firstAndLastGutter={true}
        freeScrolling={false}
        requestToChangeActive={setActive}
        activeItemIndex={active}
        activePosition={'center'}
        chevronWidth={24}
        rightChevron={'>'}
        leftChevron={'<'}
        outsideChevron={false}
    >
        {images.map(i => (
        <div className="item-details-carousel" key={i}>
            <img src={i.image ? i.image : productImage} onClick={() => handleChange(i.image)} />
        </div>
        ))}
    </ItemsCarousel>
    </div>
  );
};

export default ImageCarousel;
