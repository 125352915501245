import React from 'react';
import { Navbar, Nav, NavItem, Row, Col } from 'reactstrap';
import StorageService from '../../../services/StorageService';
const version = process.env.REACT_APP_VERSION;

const TopFooter = props => {

  const store = StorageService.getSession('store');

  const name = store ? store.name : '';
  const desc = store ? store.web_footer : '';
  return (
    <div className="ps-top-footer" style={{background: sessionStorage.getItem('_theme') ? sessionStorage.getItem('_theme') : '#544b82'}}>
      <div className="container">
        <Row className="align-items-center">
          <Col md={4}>
            <h1>{name}</h1>
          </Col>
          <Col md={8}>
            <p
              dangerouslySetInnerHTML={{
                __html: desc,
              }}
            ></p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TopFooter;
