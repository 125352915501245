import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import Notifications from 'components/Notifications';
import SearchInput from 'components/SearchInput';
import { notificationsData } from 'demos/header';
import withBadge from 'hocs/withBadge';
import React from 'react';
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from 'utils/bemnames';
import { mobileMenuData } from 'data/menu';
import { NavLink as Link } from 'react-router-dom';
import { IoIosClose, IoIosCart, IoIosMenu } from 'react-icons/io';
import ls from '../../../services/LocalStorageService';
import StorageService from '../../../services/StorageService';

class OverlayMenu extends React.Component {
  handleOverlayMenu = event => {
    event.preventDefault();
    event.stopPropagation();
    document.querySelector('.ps-overlay-menu').classList.toggle('open');
  };

  handleClick = () => {
    document.querySelector('.ps-overlay-menu').classList.toggle('open');
  };

  render() {
    const user = StorageService.getSession('consumer');
    const logo = this.props.store ? this.props.store.image_url : '';
    const company = this.props.company ? this.props.company.CompanyCode : '';
    const store = this.props.store ? this.props.store.store_code : '';

    let home_url = `/${company}/${store}`;

    if(this.props.company) {
      if(this.props.company.Has_Store === 1) {
        home_url = `/${company}`;
      }
    }

    return (
      <div className="ps-overlay-menu">
        <IoIosClose className="closebtn" onClick={this.handleOverlayMenu} />

        <div className="overlay-content">
          <Avatar src={logo} size={60} />
          <br />
          <div className="top-info">
            <Link to={home_url} className="mr-2 ps-logo-wrapper">
              <span>Home</span>
            </Link>
          </div>

          {user === null ? (
            mobileMenuData.map(({ to, name, exact }, index) => (
              <Link
                to={`/${company}/${store}${to}`}
                exact={exact}
                key={index}
                onClick={this.handleClick}
              >
                {name}
              </Link>
            ))
          ) : (
            <>
              <Link
                to={`/${company}/${store}/your-profile`}
                exact={false}
                onClick={this.handleClick}
              >
                Profile
              </Link>
              <Link
                to={`/${company}/${store}/track-order`}
                exact={false}
                onClick={this.handleClick}
              >
                Track My Order
              </Link>
              <p onClick={this.props.handleLogout}>Logout</p>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default OverlayMenu;
