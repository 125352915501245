import React from 'react';
import { Navbar } from 'reactstrap';
import bn from 'utils/bemnames';

const bem = bn.create('header');

class FacebookTopHeader extends React.Component {
  render() {
    const logo = this.props.company ? this.props.company.CompanyLogo : '';
    const name = this.props.company ? this.props.company.CompanyName : '';

    return (
      
      <Navbar light expand className={bem.b('bg-white')}>
        <div className="container">
            <div className="mr-2 ps-logo-wrapper">
                <img src={logo} className="ps-header-logo" />
                <span>{name}</span>
            </div>
        </div>
      </Navbar>
    );
  }
}

export default FacebookTopHeader;
