import StorageService from '../services/StorageService';
import moment from 'moment-timezone';
import { IoIosCloseCircle } from 'react-icons/io';
const redirect_url = process.env.REACT_APP_REDIRECT_URL;

export const func = {
    formatDate(date) {
      const d = new Date(date);
      let yy = d.getFullYear();
      let mm = d.getMonth() + 1;
      let dd = d.getDate();
      let h = d.getHours();
      let m = d.getMinutes();

      mm = mm < 10 ? '0' + mm : mm;
      dd = dd < 10 ? '0' + dd : dd;
      m = m < 10 ? '0' + m : m;

      return yy + '-' + mm + '-' + dd + ' ' + h + ':' + m;
    },
    calculateDistance(mylat, mylong, lat, long) {
        let R = 6371; // km
        let dLat = this.toRad(lat-mylat);
        let dLon = this.toRad(long-mylong);
        let lat1 = this.toRad(mylat);
        let lat2 = this.toRad(lat);

        let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        let d = R * c;
        return d;
    },

    financial(x) {
        let twodn = Math.round((Number.parseFloat(x) + Number.EPSILON) * 100) / 100;
        return twodn.toFixed(2);
    },

    checkExceedMaxQty(product, quantity, include_cart) {
      let cart = StorageService.getSession('cart');

      let cart_item = [];
      if(cart) {
          cart_item = cart.filter(el=>el.id == product.id && el.store_id == product.store_id);
      }

      let cart_item_qty = 0;

      for(let c of cart_item) {
        cart_item_qty += c.quantity;
      }

      let qty = parseInt(quantity);
      if(include_cart) {
        qty += parseInt(cart_item_qty);
      }


      if(product.product_condition.length > 0) {
        let now_date = moment().format('YYYY-MM-DD');
        for(let i of product.product_condition) {
          if(i.condition_type === 'MaxQuantity') {
            let start_date = moment(i.start_date).format('YYYY-MM-DD');
            let end_date = moment(i.end_date).format('YYYY-MM-DD');
            if(now_date >= start_date && now_date<= end_date) {
              if(qty > parseInt(i.params)) {
                let str = '';
                if(cart_item_qty > 0) {
                  str += 'You have '+ cart_item_qty + ' in your cart. ';
                }
                return str+'You are allowed to purchase maximum ' + i.params + ' per order for this item';
              }
            }
          }
        }
      }
      return '';
    },  
    
    checkMinQtyReached(product, quantity, include_cart) {
      let cart = StorageService.getSession('cart');

      let cart_item = [];
      if(cart) {
          cart_item = cart.filter(el=>el.id == product.id && el.store_id == product.store_id);
      }

      let cart_item_qty = 0; //check the quantity of the item that are already in the cart

      for(let c of cart_item) {
        cart_item_qty += c.quantity;
      }

      let qty = parseInt(quantity);
      if(include_cart) {
        qty += parseInt(cart_item_qty);
      }

      const productName = `${product.name} ${product.chn_name}`;
      if(product.product_condition.length > 0) {
        let now_date = moment().format('YYYY-MM-DD');
        for(let i of product.product_condition) {
          if(i.condition_type === 'MinQuantity') {
            let start_date = moment(i.start_date).format('YYYY-MM-DD');
            let end_date = moment(i.end_date).format('YYYY-MM-DD');
            if(now_date >= start_date && now_date<= end_date) {
              if(qty < parseInt(i.params)) {
                let str = '';
                // if(cart_item_qty > 0) {
                //   str += 'You have only '+ cart_item_qty + ' units of in your cart. ';
                // }
                return str+'You are required to purchase a minimum of ' + i.params + ' units per order for '+ productName;
              }
            }
          }

        }
      }
      return '';
    }, 

    checkCart(product, added_qty, include_cart) {
        let cart = StorageService.getSession('cart');
        let available_stock = JSON.parse(product.qty_data)[0].qty; 
        let ignore_stock = product.ignore_stock;

        let cart_item = [];
        if(cart) {
            cart_item = cart.filter(el=>el.id == product.id && el.store_id == product.store_id);
        }

        let cart_item_qty = 0;

        for(let c of cart_item) {
          cart_item_qty += c.quantity;
        }

        let total_added_quantity = parseInt(added_qty);
        if(include_cart) {
          total_added_quantity += parseInt(cart_item_qty);
        }

        if(ignore_stock < 1) {
            if(total_added_quantity > available_stock) {
            return true;
            } else {
            return false;
            }
        } else {
            return false;
        }
    },

    checkPriceMod (product, quantity) {
        let price = JSON.parse(product.price_data)[0].price;
        if(product.price_mod.length) {
            for(let pm of product.price_mod) {
                if(quantity >= pm.min_qty && quantity <= pm.max_qty) {
                    price = JSON.parse(pm.price_data)[0].price;
                }
            }
        }
        return price;
    },

    randStr(strlength) {
        let rand = '';
        let characters =
          'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (var i = 0; i < strlength; i++) {
          rand += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
    
        return rand.toUpperCase();
    },

    checkIfValidItem(set_product_list, configItems, set_type) {
        let valid = true;
        let product_list = JSON.parse(set_product_list);

        if(product_list.length < 1) {
          return false;
        }

        let nonzero = product_list.find(el=>parseInt(el.required) > 0);

        if(nonzero) {
          if(configItems.length < 1) {
            return false;
          }

          for(let i of product_list) {
            let no_r = parseInt(i.required);
            if(no_r > 0) {
              let items = configItems.filter(el=> el.product_id == i.product_id);
              if(items.length > 0) {
                let sum = items.map(e => e.qty).reduce((prev, next) => prev + next);
                if(sum < no_r) {
                  valid = false;
                }
              } else {
                valid = false;
              }
            }
          }
        }
        return valid;
    },

    checkComboIfValidItem(combo, configItems) {
      let valid = true;

      if(combo.length < 1) {
        return false;
      }

      let nonzero = combo.find(el=>parseInt(el.required) > 0);

      if(nonzero) {
        if(configItems.length < 1) {
          return false;
        }
      }

      for(let c of combo) {
        let no_r = parseInt(c.required);
        if(no_r > 0) {
          let items = configItems.filter(el=> el.id == c.id);
          if(items.length < no_r) {
            return false;
          } else {
            let product_list = JSON.parse(c.product_list);
            let subnonzero = product_list.find(el=>parseInt(el.required) > 0);
            if(subnonzero) {
              for(let p of product_list) {
                let sub_no_r = parseInt(p.required);
                if(sub_no_r > 0) {
                  let a = configItems.filter(el=> el.product_id == p.product_id);
                  if(a.length > 0) {
                    let sum = a.map(e => e.qty).reduce((prev, next) => prev + next);
                    if(sum < sub_no_r) {
                      valid = false;
                    }
                  } else {
                    valid = false;
                  }
                }
              }
            }
          }
        }
      }
      
      return valid;
  },

    addToCart(store_code, product, added_quantity, configItems, allProduct) {
        let cartItem = StorageService.getSession('cart');
        let limitReached = this.checkCart(product, added_quantity);
        if(limitReached) {
            alert('Quantity exceed order limit.');
            return false;
        } 

        if(product.product_type === 'set') {
            let isValid = this.checkIfValidItem(product.set.product_list, configItems, product.set.set_type);

            if(!isValid) {
                alert('Invalid selected item.');
                return;
            }
        }

        let newArr = cartItem && cartItem.length ? cartItem : [];
        let found = newArr.some(el => el.id === product.id && el.store_id == product.store_id);
        let quantity = parseInt(added_quantity);
        let cartitem_id = this.randStr(20);
        let available_dates = [];

        let d_start = '';
        let d_end = '';

        if(product.product_condition.length) {
          for(let i of product.product_condition) {
            if(i.condition_type === "Available") {
              available_dates.push({
                start_date: i.start_date,
                end_date: i.end_date
              });

              d_start = i.start_date;
              d_end = i.end_date;
            }
          }
        }

        if (found) {
            let item = newArr.find(el => el.id === product.id && el.store_id == product.store_id);
            if(product.product_type === 'set') {
                newArr.push({
                    cartitem_id: cartitem_id,
                    store_code: store_code,
                    id: product.id,
                    product_no: product.product_no,
                    unit: JSON.parse(product.price_data)[0].unit,
                    custom_product: product.custom_product ? product.custom_product : null,
                    product_type: product.product_type,
                    name: product.name,
                    image: product.image,
                    quantity: quantity,
                    price: this.checkPriceMod(quantity),
                    available_dates: available_dates,
                    d_start: d_start,
                    d_end: d_end,
                    type: 'main'
                  });
            } else {
                let currentQty = parseInt(item.quantity);
                currentQty += quantity;
                item.quantity = currentQty;
                item.price = this.checkPriceMod(product, currentQty);
            }
        } else {
            newArr.push({
                cartitem_id: cartitem_id,
                store_code: store_code,
                id: product.id,
                product_no: product.product_no,
                unit: JSON.parse(product.price_data)[0].unit,
                custom_product: product.custom_product ? product.custom_product : null,
                product_type: product.product_type,
                name: product.name,
                image: product.image,
                quantity: quantity,
                price: this.checkPriceMod(product, quantity),
                available_dates: available_dates,
                d_start: d_start,
                d_end: d_end,
                type: 'main'
            });
        }

        if(product.product_type === 'set') {
            if(configItems.length) {
                for(let i of configItems) {
                    let p = allProduct.find(el => el.id == i.product_id);
                    if(p) {
                      newArr.push({
                        cartitem_id: cartitem_id,
                        store_code: store_code,
                        id: i.product_id,
                        product_no: p.product_no,
                        unit: JSON.parse(p.price_data)[0].unit,
                        custom_product: p.custom_product ? p.custom_product : null,
                        product_type: p.product_type,
                        name: p.name,
                        image: p.image,
                        quantity: i.qty,
                        price: '0.00',
                        available_dates: '',
                        d_start: '',
                        d_end: '',
                        type:'sub'
                      });
                    } 
                  }
            }
          }

        StorageService.setSession('cart', newArr);
        alert('Added to cart');
        return true;
    },
    isClose(store) {
      let isClose = true;
      if(store.storeopen > 0) {
        isClose = false;
      }
      
      return isClose;
    },

    redirect() {
      let company = StorageService.getSession('company');
      let store = StorageService.getSession('store');
      let redirect = false;

      if(store) {
        let isClose = this.isClose(store);
        if(!isClose) {
          if(store.enable_silo === 1) {
              return '';
          } else {
            redirect = true;
          }
        } else {
          redirect = true;
        }
      }
  
      if(redirect) {
        if(company && company.Has_Store === 1) {
          return `/${company.CompanyCode}/${store.store_code}`;
        } else {
          return redirect_url;
        }
      }
    },

    splitTags(str) {
      let tags = '';
      if (str) {
        tags = str.split(', ');
      }
      return tags;
    },

    getIncrementValue(index, arr) {
      let combo_arr = arr;
      let total_val = 0;
      if(index < 1) {
        total_val = 0;
      } else {
        for(let i=0; i<index;i++) {
          total_val += combo_arr[i].item_count;
        }
      }
      return total_val;
    },
    updateCartBadge() {
      try {
        let cart = sessionStorage.getItem('cart');
        let count = 0;
        if(cart) {
          let k = JSON.parse(cart);
          if(k.length > 0) {
            let c = k.filter(i => i.type === 'main');
            count = c.length;
          }
        } 
  
        document.querySelectorAll('.ps-cart-counter').forEach(function(a) {
          a.remove()
        });
  
        if(count > 0) {
          var node = document.createElement("span");  
          node.classList.add('ps-cart-counter');              
          var textnode = document.createTextNode(count);         
          node.appendChild(textnode); 
  
          var windowSize = window.innerWidth; 
          if(windowSize > 992){
            document.getElementById("d-cart-number").appendChild(node);
          } else {
            document.getElementById("m-cart-number").appendChild(node);
          }
        }
      } catch (err) {
        console.error(err);
      }
      
    }
};