import React from 'react';
import Page from 'components/Page';
import addToCartImage from 'assets/img/cart.png';

class AddToCartConfirmationPage extends React.Component {

  render() {
    return(
        <Page className="container ps-page-content" title="">
            <div className="cart-thank-you">
                <img src={addToCartImage}/>
                <h3>Your item has been added to your cart.</h3> 
                <span>Please check your email for further instruction.<br/> Thank You.</span>
            </div>
        </Page>
    );
  }
}
export default AddToCartConfirmationPage;
