import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import StorageService from 'services/StorageService';
const pathname = window.location.pathname.split('/');

export const ProtectedRoute = ({ component: Component, path: path, ...props }) => {
  
  return (
  <Route 
    exact
    path={path}
    {...props} 
    render={props => (
      // const { pathname } = props.location; //{"pathname": "/mpos_demo_company/DM","search": "","hash": ""}
      // this.lastLocation = pathname;
      StorageService.getSession('consumer') ? <Component {...props} /> : <Redirect to={{
        pathname: `/${pathname[1]}/${pathname[2]}/login`,
        state: { referer: props.location }}}/>
    )
      
    } 
  />
  )
};

export const PreSignInRoute = ({ component: Component, path: path, ...props }) => {
  return (
    <Route 
      exact
      path={path}
      {...props} 
      render={props => (
        StorageService.getSession('consumer') ? <Redirect to={{
          pathname: `/${pathname[1]}/${pathname[2]}`,
          state: { referer: props.location }}}/> : <Component {...props} />
      )} 
    />
  )
};