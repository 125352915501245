import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import {
  EmptyLayout,
  MainLayout,
  LayoutRoute,
  WebLayout,
  FacebookLayout
} from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import ForgotPasswordPage from 'pages/Custom/ForgotPasswordPage';
import FacebookProductPage from 'pages/Custom/FacebookProductPage';
import AddToCartConfirmationPage from 'pages/Custom/AddToCartConfirmationPage';
import StorePage from 'pages/Custom/StorePage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import StorageService from './services/StorageService';
import axios from 'axios';
import { func } from './services/function';
import { store, product, cart as cartrequest } from './services/api';
import { isDesktop, isMobile } from "react-device-detect";
import moment from 'moment-timezone';
import { ProtectedRoute, PreSignInRoute } from 'utils/customRoute';

const SiloMainPage = React.lazy(() => import('pages/Custom/SiloMainPage'));
const PrivacyPolicyPage = React.lazy(() => import('pages/Custom/PrivacyPolicyPage'));
const TermsConditionsPage = React.lazy(() => import('pages/Custom/TermsConditionsPage'));
const FAQPage = React.lazy(() => import('pages/Custom/FAQPage'));
// const StorePage = React.lazy(() => import('pages/Custom/StorePage'));
const ProductDetailPage = React.lazy(() =>
  import('pages/Custom/ProductDetailPage'),
);
const CartPage = React.lazy(() => import('pages/Custom/CartPage'));
const CheckoutPage = React.lazy(() => import('pages/Custom/CheckoutPage'));
const HomePage = React.lazy(() => import('pages/Custom/HomePage'));
const ProfilePage = React.lazy(() => import('pages/Custom/ProfilePage'));
const SetPasswordPage = React.lazy(() =>
  import('pages/Custom/SetPasswordPage'),
);
const OrderConfirmationPage = React.lazy(() =>
  import('pages/Custom/OrderConfirmationPage'),
);
const VoucherPage = React.lazy(() => import('pages/Custom/VoucherPage'));
const TrackOrderPage = React.lazy(() => import('pages/Custom/TrackOrderPage'));
const OrderDetailPage = React.lazy(() =>
  import('pages/Custom/OrderDetailPage'),
);
const OutOfStockNotificationPage = React.lazy(() =>
  import('pages/Custom/OutOfStockNotificationPage'),
);

const redirect_url = process.env.REACT_APP_REDIRECT_URL;
const pathname = window.location.pathname.split('/');
const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      store: null,
      company: null,
      key: '',
      cart: [],
      totalItems: 0,
      store_code: '',
      company_id: '',
      visibleProducts: [],
      products: [],
      isStoreClosed: false,
      isAdded: false
    };

    this.handleAddToCart = this.handleAddToCart.bind(this);
    this.handleWeightAddToCart = this.handleWeightAddToCart.bind(this);
    this.checkIsCartEmpty = this.checkIsCartEmpty.bind(this);
  }
  
  checkIsCartEmpty(store_code) {
    let cartItem = StorageService.getSession('cart');
    let total = 0;

    if(cartItem && cartItem.length > 0) {
      let items = cartItem.filter(i => i.type === 'main');
      total = items.length;
      func.updateCartBadge();
    }
  };

  handleWeightAddToCart(store_code, product, items) {
    let total_added_quantity = 0; 

    for(let i of items) {
      total_added_quantity += i.quantity;
    }

    let limitReached = func.checkCart(product, total_added_quantity, true);
    if(limitReached) {
        alert('Quantity exceed order limit.');
        return;
    } 

    let maxQuantityReached = func.checkExceedMaxQty(product, total_added_quantity, true);
    if(maxQuantityReached) {
        alert(maxQuantityReached);
        return;
    } 

    let cartItem = StorageService.getSession('cart');
    let newArr = cartItem && cartItem.length ? cartItem : [];
    let available_dates = [];
    let d_start = '';
    let d_end = '';

    if(product.product_condition.length) {
      for(let i of product.product_condition) {
        if(i.condition_type === "Available") {
          available_dates.push({
            start_date: i.start_date,
            end_date: i.end_date
          });

          d_start = i.start_date;
          d_end = i.end_date;
        }
      }
    }

    for(let i of items) {
      let cartitem_id = func.randStr(20);
      let foundIndex = newArr.findIndex(el => el.id === product.id && el.store_id == product.store_id && el.weight === i.weight && el.type === "main");
      let price = i.price;
      if(!i.fixed) {
        price = func.checkPriceMod(product, total_added_quantity);
      }

      if(foundIndex > -1) {
        newArr[foundIndex].quantity += i.quantity;
        newArr[foundIndex].price += price;
      } else {
        newArr.push({
          cartitem_id: cartitem_id,
          store_id: product.store_id,
          store_code: product.store_code,
          id: parseInt(product.id),
          product_no: product.product_no,
          unit: JSON.parse(product.price_data)[0].unit,
          custom_product: product.custom_product ? product.custom_product : null,
          product_type: product.product_type,
          name: product.name,
          image: product.image,
          quantity: i.quantity,
          price: price,
          available_dates: available_dates,
          d_start: d_start,
          d_end: d_end,
          type: 'main',
          addOn: "",
          weight: i.weight,
          fixed_price: i.fixed
        });
      }
    }

    this.scrollTop(true);
    func.updateCartBadge();

    StorageService.setSession('cart', newArr);
    alert('Added to cart');
  };


  handleAddToCart(store_code, product, added_quantity, configItems) {
    let cartItem = StorageService.getSession('cart');
    let limitReached = func.checkCart(product, added_quantity, true);
    if(limitReached) {
        alert('Quantity exceed order limit.');
        return;
    } 

    let maxQuantityReached = func.checkExceedMaxQty(product, added_quantity, true);
    if(maxQuantityReached) {
        alert(maxQuantityReached);
        return;
    } 

    if(product.product_type === 'set') {
      let isValid = func.checkIfValidItem(product.set.product_list, configItems, product.set.set_type);

      if(!isValid) {
          alert('Invalid selected item.');
          return;
      }
    }

    if(product.product_type === 'combo') {
      let isValid = func.checkComboIfValidItem(product.combo, configItems);

      if(!isValid) {
          alert('Invalid selected item.');
          return;
      }
    }

    let newArr = cartItem && cartItem.length ? cartItem : [];
    let found = newArr.some(el => el.id === product.id && el.store_id == product.store_id && el.type === "main");
    let quantity = parseInt(added_quantity);
    let cartitem_id = func.randStr(20);
    let available_dates = [];

    let d_start = '';
    let d_end = '';

    if(product.product_condition.length) {
      for(let i of product.product_condition) {
        if(i.condition_type === "Available") {
          available_dates.push({
            start_date: i.start_date,
            end_date: i.end_date
          });

          d_start = i.start_date;
          d_end = i.end_date;
        }
      }
    }

    if (found) {
      let item = newArr.find(el => el.id === product.id && el.store_id == product.store_id && el.type === "main");
      if(product.product_type === 'set' || product.product_type === 'combo') {
          newArr.push({
              cartitem_id: cartitem_id,
              store_id: product.store_id,
              store_code: store_code,
              id: parseInt(product.id),
              product_no: product.product_no,
              unit: JSON.parse(product.price_data)[0].unit,
              custom_product: product.custom_product ? product.custom_product : null,
              product_type: product.product_type,
              name: product.name,
              image: product.image,
              quantity: quantity,
              price: func.checkPriceMod(product, quantity),
              available_dates: available_dates,
              d_start: d_start,
              d_end: d_end,
              type: 'main',
              addOn: "",
              weight: 1,
              fixed_price: false
            });
      } else {
          let currentQty = parseInt(item.quantity);
          currentQty += quantity;
          item.quantity = currentQty;
          item.price = func.checkPriceMod(product, currentQty);
      }
    } else {
        newArr.push({
            cartitem_id: cartitem_id,
            store_id: product.store_id,
            store_code: store_code,
            id: parseInt(product.id),
            product_no: product.product_no,
            unit: JSON.parse(product.price_data)[0].unit,
            custom_product: product.custom_product ? product.custom_product : null,
            product_type: product.product_type,
            name: product.name,
            image: product.image,
            quantity: quantity,
            price: func.checkPriceMod(product, quantity),
            available_dates: available_dates,
            d_start: d_start,
            d_end: d_end,
            type: 'main',
            addOn: "",
            weight: 1,
            fixed_price: false
        });
    }

    if(product.product_type === 'set' || product.product_type === 'combo') {
      if(configItems.length) {
        let sub_price = 0;
        for(let i of configItems) {
            let price = '0.00';
            if(product.product_type === 'set' && product.set.set_type==='TOP UP') {
              price = i.price;
              sub_price += parseFloat(price);
            }

            if(product.product_type === 'combo' && i.addOn==='TOP UP') {
              price = i.price;
              sub_price += parseFloat(price);
            }

            newArr.push({
              cartitem_id: cartitem_id,
              store_id: product.store_id,
              store_code: store_code,
              id: parseInt(i.product_id),
              product_no: i.product_no,
              unit: i.unit,
              custom_product: i.custom_product,
              product_type: i.product_type,
              name: i.name,
              image: i.image,
              quantity: i.qty,
              price: price,
              available_dates: '',
              d_start: '',
              d_end: '',
              type:'sub',
              addOn: i.addOn,
              weight: 1,
              fixed_price: false
            });
        }
      }
    }

    this.scrollTop(true);
    func.updateCartBadge();

    StorageService.setSession('cart', newArr);
    alert('Added to cart');
  };

  clearSavedCart() {
    const company = this.state.company ? this.state.company.CompanyID : '';
    const store = this.state.store ? this.state.store.store_code : '';
    const url = process.env.REACT_APP_UNITY_API2_URL;
    let key = StorageService.getSession('session_key');
    let consumer = StorageService.getSession('consumer');
    const formData = {
      session_key: key,
      company_id: company,
      store_code: store,
      user_session_key: consumer.user_session_key
    };

    axios
      .post(url + ':3030/v1/cart/order/clearsavedcart', formData)
      .then(response => {})
      .catch(error => {});
  };

  organizeSavedCart = (items) => {
    let arr = [];
    for(let s of items) {
      if(s.type === 'main' && s.product_type !== 'combo' && s.product_type !== 'set') {
        let i = arr.find(el => el.id == s.id && el.store_id == s.store_id);
        if(i) {
          i.quantity += s.quantity;
        } else {
          arr.push(s);
        }
      } else {
        arr.push(s);
      }
    }
    return arr;
  };

  getSavedCart = () => { 
    const company = this.state.company ? this.state.company.CompanyID : '';
    const store = this.state.store ? this.state.store.store_code : '';
    let consumer = StorageService.getSession('consumer');

    const url = process.env.REACT_APP_UNITY_API2_URL;
    let key = StorageService.getSession('session_key');
    const formData = {
      session_key: key,
      company_id: company,
      store_code: store,
      user_session_key: consumer.user_session_key,
    };
    axios
      .post(url + ':3030/v1/cart/order/getsavedcart', formData)
      .then(response => {
        const result = response.data;
        if (result.error == true && result.message != 'Success') {
          alert(result.message);
          return;
        }
        if (result.data.length > 0) {
          let arr = [];
          let remove = [];
          let newArr = [];
          let saved = this.organizeSavedCart(result.data);
          if(StorageService.getSession('cart')) {
            let cart = StorageService.getSession('cart');
            if(cart.length > 0) {
              for(let s of saved) {
                let j = cart.find(el => el.cartitem_id === s.cartitem_id);
                if(!j) { //not found in cart
                  if(s.type === 'main' && s.product_type !== 'combo' && s.product_type !== 'set') {
                    let i = cart.find(el => el.id == s.id && el.store_id == s.store_id);
                    if(i) {
                      s.quantity += i.quantity;
                      remove.push(i.cartitem_id);
                    }
                  } 
                  arr.push(s);
                }
              }
              newArr = arr.concat(cart);
            } else {
              newArr = saved;
            }
          } else {
            newArr = saved;
          }

          if(remove.length > 0) {
            for(let r of remove) {
              let idx = newArr.findIndex(el=>el.cartitem_id === r);
              if(idx > -1) newArr.splice(idx, 1);
            }
          }

          this.scrollTop(true);
          
      
          StorageService.setSession('cart', newArr);
          setTimeout(() => {
            alert('Note: Saved cart items, if any, will be removed from your cart once you have logged in.');
            this.clearSavedCart();
            func.updateCartBadge();
          }, 1000);
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  scrollTop(state) {
    this.setState({isAdded: state});
  }

  updateCartBadge() {
    try {
      let cart = sessionStorage.getItem('cart');
      let count = 0;
      if(cart) {
        let k = JSON.parse(cart);
        if(k.length > 0) {
          let c = k.filter(i => i.type === 'main');
          count = c.length;
        }
      } 

      document.querySelectorAll('.cart-badge').forEach(function(a) {
        a.remove()
      });

      if(count > 0) {
        var node = document.createElement("span");  
        node.classList.add('cart-badge');              
        var textnode = document.createTextNode(count);         
        node.appendChild(textnode); 

        var windowSize = window.innerWidth; 
        if(windowSize > 992){
          document.getElementById("d-cart-number").appendChild(node);
        } else {
          document.getElementById("m-cart-number").appendChild(node);
        }
      }
    } catch (err) {
      console.error(err);
    }
    
  }

  getCompanyDetail = () => {
    let pathname = window.location.pathname;
    let company = pathname.split('/')[1];

    if (window.location.host.split(".")[0] == "perferoasia") {
      company = 'PerferoAsia';
    }    
    
    const url = process.env.REACT_APP_API_URL;
    const data = {
      CompanyCode: company,
    };

    axios
      .post(url + ':3000/v1/ecart/getCompany', data)
      .then(response => {
        const result = response.data;

        if (result.error == true && result.message != 'Success') {
          //alert(result.message);
          return;
        }

        if (result.data) {
          let scompany = StorageService.getSession('company');
          if(scompany) {
            let company_id = scompany.CompanyID;
            if(company_id !== result.data.CompanyID) {
              StorageService.clearSession('_sselected');
              StorageService.clearSession('cart');
            }
          }
          StorageService.setSession('company', result.data);
          StorageService.clearSession('_pselected');
          let c = StorageService.getSession('consumer');
          if (c && c.company !== company) {
            StorageService.clearSession('consumer');
            StorageService.clearSession('access');
          }

          this.setState({ company: result.data, company_id: result.data.CompanyID });
          this.getStoreDetail(result.data.CompanyID);
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  getStoreDetail = (id) => {
    let access = sessionStorage.getItem('access');
    let pathname = window.location.pathname;
    let store = pathname.split('/')[2];

    if (window.location.host.split(".")[0] == "perferoasia") {
      store = pathname.split('/')[1];
    }    

    const url = process.env.REACT_APP_UNITY_API2_URL;
    if(!store) {
      return;
    }

    const data = {
      session_key: process.env.REACT_APP_API_KEY,
      company_id: id,
      store_code: store,
      local_time: moment().format('YYYY-MM-DD HH:mm:ss') 
    };

    axios
      .post(url + ':3030/v2/cart/store', data)
      .then(response => {
        const result = response.data;

        if (result.data) {
          let store = result.data;

          let sstore = StorageService.getSession('store');
          if(sstore) {
            let store_id = sstore.store_id;
            if(store_id !== store.store_id) {
              StorageService.clearSession('cart');
            }
          }

          StorageService.setSession('store', result.data);

          this.setState({ 
            store: result.data, 
            store_code: result.data.store_code 
          });

          if(store) {
            let isClose = true;
            if(store.storeopen > 0) {
              isClose = false;
              if (store.enable_silo < 1) {
                isClose = true;
                window.location.replace(redirect_url);
              } else {
                StorageService.setSession('fp', result.data.pricefilter.from_price + ';' + result.data.pricefilter.to_price);
                this.checkIsCartEmpty(result.data.store_code); 
              }
            }

            this.setState({ isStoreClosed: isClose });
          }
          
        } else {
          // window.location.replace(redirect_url);
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  checkPriceMod = product => {
    let quantity = 1;
    let price = "";
    let mod = false;
    if(product.product_type === 'weight') {
      if(product.weight && product.weight.length > 0) {
        if(!product.weight[0].fixed_price) {
          mod = true;
        }
      }
    } else {
      mod = true;
    }

    if(mod) {
      if(product.price_mod.length) {
        for(let pm of product.price_mod) {
          if(quantity >= pm.min_qty && quantity <= pm.max_qty) {
            price = JSON.parse(pm.price_data)[0].price;       
          } 
        }
      }
    }

    return price;
  };

  getKey = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tag = urlParams.get('tag');
    const url = process.env.REACT_APP_UNITY_API2_URL;

    const data = {
      api_key: 'PeySswrdyFpFGWZXGSkc',
      web_id: this.generateWebId(40),
      tag: tag ? tag : '',
      browser_type: isMobile ? 1 : isDesktop ? 2 : 0
    };

    axios
      .post(url + ':3030/v2/cart/session', data)
      .then(response => {
        const result = response.data;

        if (result.error == true && result.message != 'Success') {
          return;
        }

        if (result.data) {
          StorageService.setSession('session_key', result.data);
          this.checkValidCall(result.data);
        }
      })
      .catch(error => {
        //alert(error);
      });
  };

  checkValidCall = () => {
    let session_key = StorageService.getSession('session_key');
    let paths = window.location.pathname.split('/');
    if (paths.length >= 2) {
      if (!session_key) {
        this.getKey();
      }
      this.getCompanyDetail();
    }
  };

  generateWebId = length => {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    StorageService.setSession('web_id', result);
    return result;
  };

  openLoader(openOrClose) {
    if (openOrClose === 'open') {
      return document.querySelector('.ps-loader').classList.add('loading');
    }
    document.querySelector('.ps-loader').classList.remove('loading');
  }

  componentDidMount() {   
    if (window.location.host.split(".")[0] == "perferoasia") {
      window.location = process.env.REACT_APP_SOURCE_URL + 'PerferoAsia/' + window.location.pathname.split('/')[1];
    }

    this.checkValidCall();
  }
  render() {
    const { store, company } = this.state;
    if (window.location.host.split(".")[0] == "fb") {
      return (
        <BrowserRouter basename={getBasename()}>
          <GAListener>
            <Switch>
              <FacebookLayout company={company}>
                <Route
                    exact
                    path="/:company/:store/p/:product"
                    component={props => (
                      <FacebookProductPage {...props} openLoader={this.openLoader} store={store}
                      company={company} addToCart={this.handleAddToCart} />
                    )}
                />
                <Route
                    exact
                    path="/:company/:store/added-to-cart"
                    component={props => (
                      <AddToCartConfirmationPage {...props} store={store}
                      company={company}/>
                    )}
                />
              </FacebookLayout>
            </Switch>
          </GAListener>
        </BrowserRouter>
      );
    }

    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>          
            <WebLayout
              breakpoint={this.props.breakpoint}
              store={store}
              company={company}
              totalItems={this.state.totalItems}
              updateCartBadge={this.updateCartBadge}
              addToCart={this.handleAddToCart}
              checkIsCartEmpty={this.checkIsCartEmpty}
            >
              <React.Suspense fallback={<PageSpinner />}>
                <Route
                  exact
                  path="/"
                  component={() => {
                    window.location.href = redirect_url;
                    return null;
                  }}
                />

                
                <Route exact path="/:company" component={props => (
                    <StorePage
                      {...props}
                      store={this.state.store}
                      company={this.state.company}
                    />
                  )}
                />

                <ProtectedRoute path="/:company/:store" component={props => (
                  <SiloMainPage {...props} openLoader={this.openLoader}
                  breakpoint={this.props.breakpoint}
                  store={this.state.store}
                  company={this.state.company}
                  addToCart={this.handleAddToCart}
                  isStoreClosed={this.state.isStoreClosed}
                  isAdded={this.state.isAdded}
                  scrollTop={this.scrollTop}
                  weightAddToCart={this.handleWeightAddToCart}/>
                )}/>

                <Route
                  exact
                  path="/:company/:store/privacy-policy"
                  component={props => (
                    <PrivacyPolicyPage
                      {...props}
                      store={this.state.store}
                      company={this.state.company}
                    />
                  )}
                />

                <Route
                  exact
                  path="/:company/:store/terms-and-conditions"
                  component={props => (
                    <TermsConditionsPage
                      {...props}
                      store={this.state.store}
                      company={this.state.company}
                    />
                  )}
                />

                <Route
                  exact
                  path="/:company/:store/faq"
                  component={props => (
                    <FAQPage
                      {...props}
                      store={this.state.store}
                      company={this.state.company}
                    />
                  )}
                />

                <PreSignInRoute path="/:company/:store/login" component={props => (
                  <AuthPage
                  {...props}
                  authState={STATE_LOGIN}
                  store={this.state.store}
                  company={this.state.company}
                  getSavedCart={this.getSavedCart}
                />
                )}/>

                

                <PreSignInRoute path="/:company/:store/forgot-password" component={props => (
                  <ForgotPasswordPage
                    {...props}
                    store={this.state.store}
                    company={this.state.company}
                  />
                )} />  

                <PreSignInRoute path="/:company/:store/set-password" component={props => (
                    <SetPasswordPage
                      {...props}
                      store={this.state.store}
                      company={this.state.company}
                    />
                  )} /> 


                <ProtectedRoute path="/:company/:store/your-profile" component={props => (
                    <ProfilePage
                      {...props}
                      store={this.state.store}
                      company={this.state.company}
                    />
                  )} />

                  <ProtectedRoute path="/:company/:store/voucher" component={props => (
                    <VoucherPage
                      {...props}
                      store={this.state.store}
                      company={this.state.company}
                    />
                  )} />

                  <ProtectedRoute path="/:company/:store/checkout" component={props => <CheckoutPage
                      openLoader={this.openLoader}
                      store={this.state.store}
                      company={this.state.company}
                      {...props}
                    />} />

                  <ProtectedRoute path="/:company/:store/track-order" component={props => <TrackOrderPage
                      store={this.state.store}
                      company={this.state.company}
                      {...props}
                    />} />

                  <ProtectedRoute path="/:company/:store/order-confirmation/:order" component={props => <OrderConfirmationPage
                    openLoader={this.openLoader}
                      store={this.state.store}
                      company={this.state.company}
                      {...props}
                    />} />

                  <ProtectedRoute path="/:company/:store/order/:order" component={props => <OrderDetailPage
                      store={this.state.store}
                      company={this.state.company}
                      {...props}
                    />} />

                  <ProtectedRoute path="/:company/:store/out-of-stock" component={props => <OutOfStockNotificationPage
                      store={this.state.store}
                      company={this.state.company}
                      {...props}
                    />} />
              </React.Suspense>
            </WebLayout>
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
