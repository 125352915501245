import React from 'react';
import ReactDOM from 'react-dom';
import {isIE} from 'react-device-detect';
import { FaChrome, FaFirefox, FaSafari } from "react-icons/fa";

import App from './App';

const UnsupportedBrowserPage = () => (
    <div id='bc-message'>
        <div className='content'>
            <h1>Browser not supported</h1>
            <p>You're using a web browser we don't support. Please make sure your browser is fully updated or try one of these options to have a better experience.</p>
            <div className="supported-browser">
                <div><FaChrome/><br/>Chrome</div>
                <div><FaFirefox/><br/>Firefox</div>
                <div><FaSafari/><br/>Safari</div>
            </div>
        </div>
    </div>
);


if (isIE) {
    ReactDOM.render(<UnsupportedBrowserPage />, document.getElementById('root'));
} else {
    ReactDOM.render(<App />, document.getElementById('root'));
}

