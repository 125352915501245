import axios from 'axios';
import StorageService from '../services/StorageService';
import en from '../messages/en';
import cartapi from '../data/api';
import { database } from 'faker';
const url = "https://unityapi2.pseudoscops.com";
const unity_url = "https://zosuite.pseudoscops.com";

export const recaptcha = {
    validateHuman(token) {
        const data = {
            token: token,
        };
      
        return axios
        .post(unity_url + cartapi.verify_captcha, data)
        .then(response => {
            const result = response.data;
            if (result.data) {
                return result.data.success;
            }

            return false; //boolean
        })
        .catch(error => {
            return false;
        });
    },
};

export const auth = {
    forgotPassword(store, id, company) {
        const key = StorageService.getSession('session_key');
 
        const data = {
            session_key: key,
            store_code: store,
            user_id: id,
            company_id: company,
        };
      
        return axios
        .post(url + cartapi.get_token, data)
        .then(response => {
            alert(en.request_pin_success);
            return true; //boolean
        })
        .catch(error => {
            alert(error.response.data.message);
            return false;
        });
    },
};

export const product = {
    getProductCategory(company_id, store_code){
        const key = StorageService.getSession('session_key');

        const data = {
          session_key: key,
          company_id: company_id,
          store_code: store_code
        };
    
        return axios
          .post(url + cartapi.get_product_category, data)
          .then(response => {
            const result = response.data;
            return result.data; //array
          })
          .catch(error => {
            //alert('Something went wrong. Please try to reload');
            return [];
          });
      },
};

export const store = {
    searchAddress(postal_code){
        let api = `https://www.onemap.gov.sg/api/common/elastic/search?searchVal=${postal_code}&returnGeom=Y&getAddrDetails=Y&pageNum=1`;
    
        return axios
        .get(api)
        .then(response => {
            return response;
        })
        .catch(error => {
          alert(error);
          return null;
        });
    },

    getStoreProduct(company_id, store_code) {
        const key = StorageService.getSession('session_key');
        const consumer = StorageService.getSession('consumer');
        const data = {
            session_key: key,
            company_id: company_id,
            store_code: store_code,
            user_session_key: consumer.user_session_key,
        };
      
        return axios
        .post(url + cartapi.get_store_product, data)
        .then(response => {
            const result = response.data;
            return result.data; //array
        })
        .catch(error => {
            alert('Service went wrong. Please reload and try again');
            return [];
        });
    },

    getPrivacy() {
        const key = StorageService.getSession('session_key');
        const store = StorageService.getSession('store');
        const company = StorageService.getSession('company');

        if(!store || !company) {
            return;
        }

        const data = {
            session_key: key,
            company_id: company.CompanyID,
            store_code: store.store_code,
        };
      
        return axios
        .post(url + ':3030/v1/cart/store/privacy', data)
        .then(response => {
            const result = response.data;
            return result.data; 
        })
        .catch(error => {
            return null;
        });
    },

    getTerms() {
        const key = StorageService.getSession('session_key');
        const store = StorageService.getSession('store');
        const company = StorageService.getSession('company');

        if(!store || !company) {
            return;
        }

        const data = {
            session_key: key,
            company_id: company.CompanyID,
            store_code: store.store_code,
        };
      
        return axios
        .post(url + ':3030/v1/cart/store/terms', data)
        .then(response => {
            const result = response.data;
            return result.data; 
        })
        .catch(error => {
            return null;
        });
    },

    getFaq() {
        const key = StorageService.getSession('session_key');
        const store = StorageService.getSession('store');
        const company = StorageService.getSession('company');

        if(!store || !company) {
            return;
        }

        const data = {
            session_key: key,
            company_id: company.CompanyID,
            store_code: store.store_code,
        };
      
        return axios
        .post(url + ':3030/v1/cart/store/faq', data)
        .then(response => {
            const result = response.data;
            return result.data; 
        })
        .catch(error => {
            return null;
        });
    }
};

export const order = {
    getOrderList(props) {
        const key = StorageService.getSession('session_key');
        const consumer = StorageService.getSession('consumer');
        const data = {
            session_key: key,
            user_session_key: consumer.user_session_key,
        };
      
        return axios
        .post(url + cartapi.get_customer_order, data)
        .then(response => {
            const result = response.data;
            return result.data; //array
        })
        .catch(error => {
            if(props.company && props.store) {
                if (error.response) {
                    if(error.response.status === 401) {
                        alert('Session expired. Please log in again.');
                        StorageService.clearSession('consumer');
                        StorageService.clearSession('access');
                        window.location.replace(`/${props.company.CompanyCode}/${props.store.store_code}/login`);
                    } else {
                        alert('Error');
                    }
                }
            }
            
            return [];
        });
    },
    getOrderDetail(store_code, order_id, props) {
        const key = StorageService.getSession('session_key');
        const consumer = StorageService.getSession('consumer');
        const data = {
            session_key: key,
            user_session_key: consumer.user_session_key,
            order_id: order_id,
            store_code: store_code,
        };
      
        return axios
        .post(url + cartapi.get_order_detail, data)
        .then(response => {
            const result = response.data;
            return result.data; //object
        })
        .catch(error => {
            if(props.company && props.store) {
                if (error.response) {
                    if(error.response.status === 401) {
                        alert('Session expired. Please log in again.');
                        StorageService.clearSession('consumer');
                        StorageService.clearSession('access');
                        window.location.replace(`/${props.company.CompanyCode}/${props.store.store_code}/login`);
                    } else {
                        alert('Error');
                    }
                }
            }
            //alert('Something went wrong. Please try to reload');
            return null;
        });
    }
};

export const cart = {
    get(formData){
        const key = StorageService.getSession('session_key');

        const data = {
          session_key: key,
          ...formData
        };
    
        return axios
          .post(url + ':3030/v1/cart/order/cart/data', data)
          .then(response => {
            return response.data.data; //array
          })
          .catch(error => {
            return null;
          });
    },

    save(formData){
        const key = StorageService.getSession('session_key');

        const data = {
          session_key: key,
          ...formData
        };
    
        return axios
          .post(url + ':3030/v1/cart/order/cart/data/save', data)
          .then(response => {
            return true; //array
          })
          .catch(error => {
            return false;
          });
    },
};