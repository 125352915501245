import React from 'react';
import PropTypes from 'utils/propTypes';
import bn from 'utils/bemnames';
import Avatar from 'components/Avatar';
import weblogo from 'assets/img/logo/logo2.png';
import titleBarBgImage from 'assets/img/bg/background_1920-21.jpg';
import storeImage from 'assets/img/store/placeholder.jpg';
import deco from 'assets/img/store/deco.png';

const bem = bn.create('page');
// const banner = sessionStorage.getItem('_banner') ? sessionStorage.getItem('_banner') : titleBarBgImage;

// const background = {
//   backgroundImage: `url("${banner}")`,
// };

const storeProfile = {
  backgroundColor: '#fff',
};

const TitleBar = ({ store, data: data, tag: Tag, ...restProps }) => {
  //const classes = bem.b('px-3', className);

  return (
    <Tag className="ps-title-bar" {...restProps} style={{
      backgroundImage: `url("${data.info.banner ? data.info.banner : titleBarBgImage}")`,
    }}>
      <div className="container">
        <div className="d-flex align-items-center content">
          {data.info && 
          <div className="profile-img-wrapper"> 
            <img className="santa bottom" src={deco}/>
          <Avatar src={data.info.icon} style={storeProfile} />
          </div>
          }

          <div className="ml-4 details">
            <h1>{data.page}</h1>
            {data.info && (
              <>
                <span>{data.info.location}</span>
              </>
            )}
          </div>
        </div>
      </div>
    </Tag>
  );
};

TitleBar.propTypes = {
  tag: PropTypes.component,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  children: PropTypes.node,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      active: PropTypes.bool,
    }),
  ),
};

TitleBar.defaultProps = {
  tag: 'div',
  title: '',
};

export default TitleBar;
