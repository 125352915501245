import React from 'react';

import { Navbar, Nav, NavItem } from 'reactstrap';

import SourceLink from 'components/SourceLink';
const version = process.env.REACT_APP_VERSION;

const Footer = () => {
  return (
    <Navbar className="ps-footer">
      <div className="container">
        <Nav navbar>
          <NavItem>
            © 2022 {new Date().getFullYear() != "2022" && `- ${new Date().getFullYear()}`} <a href="https://www.pseudoscops.com/">Pseudoscops</a>. All
            rights reserved.
            <br />
            <span>Version {version}</span>
          </NavItem>
        </Nav>
      </div>
    </Navbar>
  );
};

export default Footer;
