import React from 'react';
import PropTypes from 'utils/propTypes';

import classNames from 'classnames';

import { Card, CardTitle, CardSubtitle, CardText, CardBody } from 'reactstrap';

import Avatar from '../../Avatar';

const ProfileCard = ({
  avatar,
  avatarSize,
  title,
  subtitle,
  text,
  children,
  className,
  ...restProps
}) => {
  const classes = classNames('ps-profile-card', className);

  return (
    <Card inverse className={classes} {...restProps}>
      <CardBody>
        <div className="d-flex align-items-center">
          <Avatar src={avatar} size={avatarSize} className="mr-3" />
          <div>
            <CardTitle>{title}</CardTitle>
            <CardSubtitle>{subtitle}</CardSubtitle>
          </div>
        </div>
      </CardBody>
      {children}
    </Card>
  );
};

ProfileCard.propTypes = {
  avatar: PropTypes.string,
  avatarSize: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

ProfileCard.defaultProps = {
  avatarSize: 50,
};

export default ProfileCard;
