import Page from 'components/Page';
import React from 'react';
import {
  Card,
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { BottomHeader } from 'components/Layout';
import { NavLink as Link } from 'react-router-dom';
import Recaptcha from 'react-google-invisible-recaptcha';
import { recaptcha, auth } from 'services/api';
import cartapi from 'data/api';

class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      company: '',
      companyName: '',
      storeName: '',
      password: '',
      success: false,
    };
  }

  changeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.recaptcha.execute();
  };

  onResolved = () => {
    const token = this.recaptcha.getResponse();
    this.recaptcha.reset();
    this.validateHuman(token);
  };

  validateHuman = token => {
    recaptcha.validateHuman(token)
    .then(data => {
      if (data) {
        this.submit();
      }
    });
  };

  submit = () => {
    const { company, id } = this.state;
    auth.forgotPassword(this.props.match.params.store, id, company)
    .then(data => {
      if (data) {
        this.redirect();
      }
    });
  };

  redirect() {
    this.props.history.push(
      `/${this.props.match.params.company}/${this.props.match.params.store}/set-password`,
    );
  }

  componentDidMount() {
    this.setState({
      company: this.props.company ? this.props.company.CompanyID : '',
      companyName: this.props.company ? this.props.company.CompanyName : '',
      storeName: this.props.store ? this.props.store.name : '',
    });
  }

  render() {
    return (
      <>
        <BottomHeader
          className="ps-bottom-header"
          store={this.props.store}
          company={this.props.company}
          breadcrumbs={[]}
          isProductPage={false}
        />
        <Page className="container ps-page-content auth" title="">
          <Row className="justify-content-center">
            <Col md={8} className="ps-auth-login">
              <h2 className="ps-section-title">Forgot Password</h2>
              <Form onSubmit={this.handleSubmit} className="ps-form form-state">
                    <FormGroup>
                      <Label>mPOS User ID</Label>
                      <Input
                        type="text"
                        name="id"
                        value={this.state.id}
                        onChange={this.changeHandler}
                        required
                      />
                    </FormGroup>
                    <div className="d-flex align-items-center">
                      <Button
                        size="lg"
                        color="primary"
                        className="border-0 mr-3"
                        type="submit"
                      >
                        Request PIN
                      </Button>
                      <Link
                        to={`/${this.props.match.params.company}/${this.props.match.params.store}/login`}
                        exact={false}
                      >
                        Login
                      </Link>
                    </div>
                <Recaptcha
                  ref={ref => (this.recaptcha = ref)}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE}
                  onResolved={this.onResolved}
                  badge="bottomleft"
                />
              </Form>
            </Col>
          </Row>
        </Page>
      </>
    );
  }
}

export default ForgotPasswordPage;
