import React from 'react';
import PropTypes from 'utils/propTypes';
import bn from 'utils/bemnames';
import { IoIosOptions, IoIosRefresh } from 'react-icons/io';
import { Button } from 'reactstrap';

const bem = bn.create('page');

const FilterBar = ({
  title,
  breadcrumbs,
  tag: Tag,
  className,
  children,
  start,
  end,
  total,
  toggleFilterForm,
  showFilter,
  showStoreResetButton,
  resetStore,
  ...restProps
}) => {
  //const classes = bem.b('px-3', className);
  return (
    <Tag
      className="ps-filter-bar d-flex justify-content-between align-items-center"
      {...restProps}
    >
      <span className="list-desc">
        Showing {start}–{end} of {total} results
      </span>
      <div className="d-flex">{showFilter && (
        <Button
          color="link"
          className="theme-button-outline mr-2"
          onClick={toggleFilterForm}
        >
          <IoIosOptions /> Filter
        </Button>
      )}

      {showStoreResetButton && <Button
              color="link"
              className="theme-button-outline d-block"
              onClick={() => {
                resetStore();
              }}
            >
              <IoIosRefresh />
              Reset
            </Button>}</div>

      
    </Tag>
  );
};

FilterBar.propTypes = {
  tag: PropTypes.component,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  children: PropTypes.node,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      active: PropTypes.bool,
    }),
  ),
};

FilterBar.defaultProps = {
  tag: 'div',
  title: '',
};

export default FilterBar;
