const storageService = {
  // Store data to the session storage
  setSession(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  },

  // Get data from the session storage
  getSession(key) {
    const res = sessionStorage.getItem(key);
    return res ? JSON.parse(sessionStorage.getItem(key)) : null;
  },

  // Remove data from the session storage
  clearSession(key) {
    sessionStorage.removeItem(key);
  },
};

export default storageService;
